.card {
  border: 1px solid transparent;
}
.grid-content {
  height: 50px;
}
.info-block {
  position: relative;
  width: 100%;
  background: url("../../../../assets/nation/title_finance.png") no-repeat;
  background-size: 700px 40px;
}
.stats-label {
  margin: 10px 0 -3px 3px;
  font-weight: bold;
  color: #cececf;
}
