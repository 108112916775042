div > dd {
  margin-top: 1rem;
}
.head {
  margin-top: 2.5rem;
}
table > tr > td {
  font-size: 1rem;
}
.val1 {
  font-size: 2rem;
  font-weight: 400 ;
  color: #F4EA27;
}
.val2 {
  font-size: 1rem;
  margin-left: 10px;
  font-weight: 400 ;
  color: #CB4F43;
}
.val3 {
  font-size: 2rem;
  font-weight: 400;
  color: #CB4F43;
}
.linetitle {
  font-size: 1.1rem;
  font-weight: bold;
  color: #A0E5FB;
}
#yjzsr,
#ljjl {
  width: 6.5rem;
  height: 6.5rem;
  background: url("../../../../assets/nation/在施项目数.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#xmzsy,
#wljl {
  width: 6.5rem;
  height: 6.5rem;
  margin-left: 40px;
  background: url("../../../../assets/nation/出勤率.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#tabs {
  background: #0e1328;
}
