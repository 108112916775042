



















































































































































































































































































































































































































































































































































































































































































































































































































































































































































.text-green {
  color: #1fc86b !important;
}

.text-yellow1 {
  color: #F89132 !important;
}

.text-yellow2 {
  color: #eebc34 !important;
}

.text-red1 {
  color: #e62c31 !important;
}

.text-red2 {
  color: #f34e53 !important;
}

.text-grey {
  color: #9ca0aa !important;
}

.borderColor-green {
  border-color: #1fc86b !important;
}

.borderColor-grey {
  border-color: #9ca0aa !important;
}

.borderColor-yellow1 {
  border-color: #F89132 !important;
}

.borderColor-yellow2 {
  border-color: #eebc34 !important;
}

.borderColor-red1 {
  border-color: #e62c31 !important;
}

.borderColor-red2 {
  border-color: #f34e53 !important;
}

.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
  z-index: 30000;
}

#main {
  /*perspective: 100px;*/
  display: flex;
  padding: 1.5rem 1.875rem 2rem 1.875rem;
  height: calc(100vh - 2rem);
  overflow-y: scroll;

  #left {
    width: 20rem;

    #group-container {
      margin-bottom: 1.5rem;

      #retreat-btn {
        margin: 1rem 0;
        height: 1.875rem;
        text-align: center;
        line-height: 1.875rem;
        cursor: pointer;
        background: linear-gradient(to right, #51bbf5, #5170f5);
      }

      .group {
        height: 34.5rem;
        overflow-y: scroll;

        .group-item {

          .group-title {
            margin-bottom: 1rem;

            .group-title-icon {
              width: 1rem;
              height: 1rem;
              display: inline-block;
              margin-right: 0.25rem;
              cursor: pointer;
              position: relative;

              &.active {
                transform: rotate(-90deg);
              }

              &::before {
                content: " ";
                position: absolute;
                width: 1rem;
                font-size: 1rem;
                transform: rotate(0deg);
                transition: transform .3s ease-in-out;
                height: 1rem;
                background: url("../../assets/sanjiao.png") no-repeat;
              }
            }
          }

          .people-card-list {
            .card-item {
              padding: 0.4rem 1rem;
              display: flex;
              margin-bottom: 0.75rem;
              background-color: #151C48;
              border-radius: 0.25rem;

              .left {
                flex: 1;
                font-size: 0.88rem;
                line-height: 1.7rem;

                .title {
                  color: #999999;
                }
              }

              .right {
                width: 4rem;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .status-card {
                  border-width: 0.5rem 1px 1px;
                  border-style: solid;
                  border-radius: .25rem;
                  margin-bottom: 1rem;
                  padding: .7rem;

                  .zt {
                    font-size: 0.88rem;
                    color: #999;
                    padding: 3px 0;
                  }

                  .status-text {
                    padding-top: 5px;
                    font-size: 1.13rem;
                    font-weight: bold;
                  }
                }

                .op {
                  width: 100%;
                  height: 1.5rem;
                  background: linear-gradient(90deg, rgba(81, 189, 245, 1), rgba(81, 112, 245, 1));
                  line-height: 1.5rem;
                  cursor: pointer;
                  font-size: 0.75rem;
                  text-align: center;

                  &.disabled {
                    background: #8a8a8a;
                    cursor: not-allowed;
                  }
                }
              }
            }
          }
        }
      }
    }

    #people-by-status {
      .chart {
        height: 16rem;
      }
    }

  }

  #middle {
    flex: 1;
    display: flex;
    flex-direction: column;

    .iframe {
      flex: 1;
    }

    .floor-changer {
      margin: 1rem 0 0 1.5rem;
      display: flex;
      position: relative;

      .button {
        background: url("../../assets/qiehuan.png") no-repeat;
        width: 4.5rem;
        height: 2rem;
        cursor: pointer;
        line-height: 1.9rem;
        background-size: cover;
        text-align: center;
        font-size: 0.8rem;
        margin-right: .5rem;
      }

      .floor-list {
        border: 0.125rem solid #51bbf5;
        line-height: 1.9rem;
        font-size: 0.75rem;
        padding: 0 0.625rem;
        position: absolute;
        left: 10rem;
        width: calc(100% - 12rem);

        span {
          color: #a5a0b1;
          cursor: pointer;
          margin-right: 0.75rem;

          &.active {
            color: #5accf1;
          }
        }
      }
    }

    .project-link {
      position: fixed;
      left: 25rem;
      top: 4rem;
      width: 9rem;

      a {
        font-size: 1.1em;
        color: #5accf1;
        text-decoration: none;
        border: 1px solid #5accf1;
        padding: 1px 12px;
        border-radius: 20px;
      }
    }

    .building-changer {
      position: fixed;
      left: 25rem;
      bottom: 4rem;
      display: flex;
      width: 9rem;
      align-items: center;
      justify-content: space-between;
    }
  }

  #right {
    width: 20rem;

    #people-by-floor {
      margin-top: 1.5rem;

      .chart {
        height: 18rem;
        overflow-y: scroll;
      }
    }

    #per-floor-worker-types {
      .list-table {
        height: 36.5rem;
        overflow-y: scroll;
      }
    }

  }
}

#logo {
  position: absolute;
  right: 2rem;
  bottom: 1.5rem;
  display: flex;
  align-items: center;
  line-height: 2rem;

  span {
    font-size: 1.125rem;
    color: rgba(255, 255, 255, .6);
    margin-right: .5rem;
  }
}
