






















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.maptalks-all-layers {
  z-index: auto!important;
}

.maptalks-canvas-layer {
  z-index: auto!important;
}

.info-block {
  position: relative;
  width: 100%;
  background: url("../../assets/nation/title.png") no-repeat;
  background-size: contain;
}

.info-block-title {
  position: absolute;
  color: #c8dbf4;
  font-weight: bold;
  left: 2.8rem;
  top: 0.6rem;
}
.stats-layer .el-dialog__header {
  display: none;
}
.stats-layer .el-dialog__body {
  padding: 0;
  color: unset;
}

.option-block {
  color: #919192;
  background-color: #172142;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 6rem;
  border: 0;
  text-align: center;
  cursor: pointer;
}

.option-block-selected {
  color: #fcfcfc;
  background-color: #0a57be;
}

.el-dialog.recall-dialog {
  width: 31.25rem !important;
  background-color: rgba(21, 28, 72, .8) !important;
}

.recall-dialog .el-dialog__header, .recall-dialog .el-dialog__footer {
  display: none;
}

.recall-dialog .el-dialog__body {
  text-align: center;
  font-size: 1.25rem;
  color: #fff;
  padding: 1.5rem;
}

.recall-dialog .warning-icon {
  display: inline-block;
  background: url("../../assets/weixian.png") no-repeat;
  width: 5rem;
  height: 5rem;
  background-size: cover;
  margin-bottom: 3.5rem;
}

.recall-dialog .cancel-button, .recall-dialog .confirm-button {
  width: 6.13rem;
  height: 2rem;
  border-radius: 1rem;
  color: #ffffff;
  line-height: 2rem;
  font-size: 1rem;
  display: inline-block;
  background: rgba(117, 120, 167, 1);
  cursor: pointer;
  text-align: center;
}

.recall-dialog .confirm-button {
  margin-left: 2.5rem;
  background: linear-gradient(90deg, rgba(81, 189, 245, 1), rgba(81, 112, 245, 1));
}
