
































































































































































































































































































































































































#main {
  /*perspective: 100px;*/
  display: flex;
  padding: 1.5rem 1.875rem 2rem 1.875rem;
  height: calc(100vh - 2rem);
  overflow-y: scroll;

  #middle {
    flex: 1;
    display: flex;
    flex-direction: column;

    .iframe {
      flex: 1;
    }

    .floor-changer {
      margin: 1rem 0 0 1.5rem;
      display: flex;
      position: relative;

      .button {
        background: url("../../assets/qiehuan.png") no-repeat;
        width: 4.5rem;
        height: 2rem;
        cursor: pointer;
        line-height: 1.9rem;
        background-size: cover;
        text-align: center;
        font-size: 0.8rem;
        margin-right: .5rem;
      }

      .floor-list {
        border: 0.125rem solid #51bbf5;
        line-height: 1.9rem;
        font-size: 0.75rem;
        padding: 0 0.625rem;
        position: absolute;
        left: 10rem;
        width: calc(100% - 12rem);

        span {
          color: #a5a0b1;
          cursor: pointer;
          margin-right: 0.75rem;

          &.active {
            color: #5accf1;
          }
        }
      }
    }

    .building-changer {
      position: fixed;
      left: 25rem;
      bottom: 4rem;
      display: flex;
      width: 9rem;
      align-items: center;
      justify-content: space-between;
    }
  }
}
