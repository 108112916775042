.input-with-select {
  width: 90% !important;
  margin: 0 1rem;
}
.input-with-select .el-input__inner {
  background-color: #0c2341 !important;
  border: 1px solid #1782a6 !important;
  color: #fff !important;
  height: 2rem !important;
}
.input-with-select .cancel {
  color: #ffffff !important;
  border-left: 1px solid #1782a6 !important;
}
.input-with-select .el-input-group__append {
  color: #51bbf5 !important;
  background-color: #0c2341 !important;
  border: 1px solid #1782a6 !important;
  height: 2rem !important;
}
