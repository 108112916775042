.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}
.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}
.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
.header {
  font-size: 14px;
  border-bottom: 2px solid #5370B6;
  padding-bottom: 20px;
}
.content {
  padding-top: 20px;
}
.menu {
  background-color: #4191E5;
  padding: 4px 10px;
  border-radius: 2px;
  margin-left: 20px;
  cursor: pointer;
}
.menu i {
  margin-left: 5px;
}
.panels {
  width: 49%;
  border: 1px solid #1a3281;
  padding: 1rem;
  border-radius: 2px;
  margin-bottom: 1rem;
}
.unread {
  border: 1px solid #9C2636;
  border-radius: 2;
  color: #DE2F37;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu2 {
  background-color: #4191E5;
  padding: 6px 12px;
  border-radius: 2px;
  margin-left: 20px;
  cursor: pointer;
}
.el-step__title.is-process {
  color: #c0c4cc !important;
}
