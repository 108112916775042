#attendance {
  font-family: Tahoma, Verdana, STHeiTi, simsun, sans-serif;
}
#title {
  line-height: 6rem;
  height: 6rem;
  margin-bottom: -4rem;
  background: url("../../assets/up.png") no-repeat transparent;
  background-size: contain;
  position: relative;
}
.date {
  display: flex;
  position: absolute;
  font-size: 1.2rem;
  font-weight: 500;
  top: -25%;
  left: 3%;
}
.project {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#main {
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0 5rem 0;
  height: calc(100vh - 2rem);
  overflow-y: scroll;
}
#main #top {
  display: flex;
  height: 22rem;
  margin-top: 1.5rem;
  justify-content: space-between;
}
#main #bottom {
  display: flex;
  height: 30rem;
  justify-content: space-between;
}
#main #bottom #equipment-status .content {
  background: url("../../assets/shebei.png") no-repeat 4px 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 90px;
  height: 5.375rem;
  font-size: 0.875rem;
}
#main #bottom #equipment-status .content p {
  line-height: 1.5rem;
}
#main #bottom #people-status .content {
  display: flex;
  padding: 1rem 0 0.5rem;
}
#main #bottom #people-status .content .left {
  width: 45%;
  text-align: center;
}
#main #bottom #people-status .content .left .score {
  padding: 0.4rem 0;
  font-size: 1.875rem;
  color: #1fc86b;
}
#main #bottom #people-status .content .left .name {
  font-size: 0.875rem;
}
#main #bottom #people-status .content .right {
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-size: 0.875rem;
}
#logo {
  position: absolute;
  right: -1rem;
  bottom: 1.5rem;
  line-height: 1.5rem;
}
#logo .watermark {
  vertical-align: text-bottom;
  width: 24%;
  height: 16%;
}
#logo span {
  font-size: 0.85rem;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.6);
  margin-right: 1rem;
}
