





































































.area_list {
  z-index: 999;
  position: absolute;
  top: 0px;
  right: 5px;
  background: #1e2c61;
  margin: 5px 0.5rem;
  border: 1px solid #5f7ec9;
  border-radius: 0.2rem;
  max-height: 96%;
  overflow-y: auto;
}

.stop-button {
  background: #43A9F1;
  font-size: 1rem;
  line-height: 2rem;
  width: 12rem;
  height: 2rem;
  border-radius: 1rem;
  margin: 1rem auto;
  text-align: center;
  cursor: pointer;
}

.area-info {
  z-index: 1;
  display: flex;
  font-size: 0.8rem;
  line-height: 1.2rem;
  padding-bottom: 0.8rem;

  .circle {
    margin: auto;
    border: 1px solid #5f7ec9;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 0.4rem;
  }

  .line {
    margin: auto;
    border: 1px solid #5f7ec9;
    width: 1px;
  }
}
