






































































































































































































































































































































































































































































































































































































































































































































































































































































.linebar {
  width: 100%;
}
.line-bar {
  display: flex;
  height: 5px;
  background-color: #0c1931;
  margin-right: 10px;
}
.real-bar {
  height: 5px;
  background-color: #57c7b6;
  border-radius: 12px;
}
.slider-trail {
  height: 3px;
  border-radius: 1px;
  background-color: rgba(#4fc08d, 0.2);
}
