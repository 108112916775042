.v-number {
  overflow: hidden;
  position: relative;
  display: inline-block;
}
.v-number__original-value {
  visibility: hidden;
}
.v-number__animated {
  position: absolute;
  transition-property: top;
  transition-timing-function: ease-in-out;
}
.v-number-new-value-enter {
  top: -100%;
}
.v-number-new-value-enter-to {
  top: 0;
}
.v-number-current-value-leave {
  top: 0;
}
.v-number-current-value-leave-to {
  top: 100%;
}
.v-number-new-value2-enter {
  top: 100%;
}
.v-number-new-value2-enter-to {
  top: 0;
}
.v-number-current-value2-leave {
  top: 0;
}
.v-number-current-value2-leave-to {
  top: -100%;
}
