



















































































































































































.vux-uploader_del {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #0d0d0d;
  color: #ffffff;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-family: 'weui';
  &:after {
    color: #ffffff;
    font-size: 22px;
    content: '\EA11';
  }
}
