





































































































































































































































































































































































































































#title-center2 {
  width: 520px;
  height: 64px;
  display: inline-block;
  line-height: 64px;
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  background: url("../../assets/nation/mid.png") transparent;
  background-size: contain;
}

#title-light {
  position: absolute;
  width: 377px;
  height: 53px;
  background: url("../../assets/nation/light2.png") transparent;
  bottom: -26.5px;
  z-index: 20000;
}

#title-left2 {
  width: 32px;
  height: 64px;
  background: url("../../assets/nation/left.png") no-repeat transparent;
  background-size: contain;
}

#title-right2 {
  width: 32px;
  height: 64px;
  background: url("../../assets/nation/right.png") no-repeat transparent;
  background-size: contain;
}

.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
  z-index: 30000;
}

#project {
  background-size: cover;
}

#logo {
  position: absolute;
  right: 2rem;
  bottom: 1rem;
  display: flex;
  align-items: center;
  line-height: 2rem;

  span {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.6);
    margin-right: 1rem;
  }
}
