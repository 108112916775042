





































































































































































































































































.menu-block {
  position: fixed;
  top: 0.5rem;
  width: 100%;
  display: flex;
  // justify-content: center;
  border-bottom: none !important;
  pointer-events: none;

  .el-menu-item {
    pointer-events: all;
    padding: 0 12px;
    font-size: 15px;
    border-bottom: none;
    width: 120px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    background: url("../../assets/nation/button.png");
  }

  .el-menu-item.is-active {
    border-bottom: none;
    background: url("../../assets/nation/button_select.png");
  }

  .el-menu-item:not(.is-disabled):hover,
  .el-menu-item:not(.is-disabled):focus {
    border-bottom: none;
    background-color: transparent !important;
  }
}

.sub-menu {
  display: flex;
  margin-top: 0.3rem;
}

.sub-menu-item {
  cursor: pointer;
  border: 1px #1a96c4 solid;
  margin-left: 0.2rem;
  font-size: 0.85rem;
  padding: 0.3rem 0.8rem;
  z-index:33333;
}

#main {
  display: flex;
  padding: 1.25rem 1.875rem 1.5rem 1.875rem;
  height: calc(100vh - 6rem);
  overflow: hidden;

  #left {
    width: 26rem;
  }

  #middle {
    position: relative;
  }

  #right {
    width: 26rem;

    #equipment-status {
      .content {
        background: url("../../assets/shebei.png") no-repeat 4px 8px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-left: 90px;
        height: 5.375rem;
        font-size: 0.875rem;

        p {
          line-height: 1.5rem;
        }
      }
    }

    #people-status {
      .content {
        display: flex;
        padding: 1rem 0 0.5rem;

        .left {
          width: 45%;
          text-align: center;

          .score {
            padding: 0.4rem 0;
            font-size: 1.875rem;
            color: #1fc86b;
          }

          .name {
            font-size: 0.875rem;
          }
        }

        .right {
          flex: 1;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          font-size: 0.875rem;
        }
      }
    }
  }
}
