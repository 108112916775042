


























































































#path {
  width: 90rem;
  height: 60rem;
}

#map {
  position: relative;
  .project_map {
    position: relative;
    background: none;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;
  }
}

#trace-mapid {
  width: 90rem;
  height: 60rem;
}

.area_list {
  z-index: 999;
  position: absolute;
  top: 0px;
  right: 5px;
  max-height: 100%;
  overflow-y: scroll;
  padding-right: 5px;
}

.area-info {
  margin-top: 5px;
  border: 1px solid #5f7ec9;
  padding: 0.5rem;
  z-index: 1;
  border-radius: 0.5rem;
  // display: inline-block;
  // position: absolute;
  // background-image: url("../../assets/bg1.png");
  background: #1e2c61;

  h3 {
    text-align: center;
    font-size: 0.9rem;
    line-height: 1.5rem;
    margin: 0;
  }

  p {
    display: flex;
    font-size: 0.9rem;
    line-height: 1.5rem;

    .title {
      width: 5rem;
    }

    .value {
      width: 6rem;
      text-align: right;
    }
  }
}

.map-angle {
  background-color: red;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.leaflet-popup-content-wrapper {
  background-color: #122040;
  text-align: center;
  border: 2px solid #5f7ec9;
}

.leaflet-popup-content > div:not(:last-child) {
  margin-bottom: 5px;
}

.leaflet-popup-tip-container {
  display: none;
}

