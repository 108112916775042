










































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































#content {
  margin-top: 0rem;
}
.screen-division {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
}
.division-item {
  width: 4rem;
  height: 2rem;
  text-align: center;
  line-height: 2rem;
  background: #21325c;
  border: 1px solid #5f7ec9;
  cursor: pointer;
}
.page-change {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 999;
}
.active-division-type {
  color: #5accf1;
}
.content-title {
  z-index: 99;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  background-image: linear-gradient(to right, #0f1f3c, rgba(255, 255, 255, 0));
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
}
.board {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #000;
  padding-top: 3rem;
}
.no-video {
  position: absolute;
  top: 30%;
  left: 40%;
}

.people-status-proj {
  width: 23rem;
  margin-top: 1rem;
  margin-right: 0.5rem;
  height: 57rem;
}

.top-item {
  height: 57rem;
  //   height: 39rem;

  width: calc(100vw - 2rem);
  display: flex;
}
.point-name {
  font-size: 16px;
  line-height: 1.8rem;
  font-weight: 400;
  color: #5accf1;
  margin-left: 1rem;
  text-decoration: underline;
}
.sure-click {
  cursor: pointer;
}
.big-content {
  width: calc(100vw - 27rem);
  height: 57rem;
  margin-right: 1rem;
  border: 1px solid #0d2b50;
  position: relative;
}
.one-screen {
  width: 100%;
  height: 100%;
}
.more-screen {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
}
.four-screen-item {
  width: 49.5%;
  height: 49%;
  position: relative;
}
.nine-screen-item {
  width: 33%;
  height: 33%;
  position: relative;
}

.img {
  width: 2.5rem;
}
