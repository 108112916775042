























































































































































































































































































































































































































































































































































































































































































































































































































































































































.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}

.value1{
  font-size:1.5rem;
  font-weight:400 ;
  color:#F4EA27
}
.value2{
  font-size:1.5rem;
  font-weight:400 ;
  color:#779DFA
}
.value3{
  font-size:1.5rem;
  font-weight:400 ;
  color:#CB4F43
}
