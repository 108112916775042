













.wrapper {
  position: relative;
  padding: 0.8rem;
  // background: url("../assets/bg1.png");
  /*background-color: #08122B;*/
  box-shadow: 0 0 12px 0 #21c1f5;
  border: solid 1px #5f7ec9;
  /*.right-top-corner {*/
  /*  width: 2.66rem;*/
  /*  height: 1px;*/
  /*  background-color: #ff5366;*/
  /*  position: absolute;*/
  /*  right: 0;*/
  /*  top: 0;*/
  /*  transform: rotate(45deg) translate(5%, 5%);*/
  /*}*/

  .decoration {
    position: absolute;
    background-color: #5accf1;
    &.left-left {
      left: -0.125rem;
      top: -0.125rem;
      width: 2rem;
      height: 0.125rem;
    }
    &.left-top {
      left: -0.125rem;
      top: -0.125rem;
      height: 2rem;
      width: 0.125rem;
    }
    &.right-bottom {
      right: -0.125rem;
      bottom: -0.125rem;
      width: 2rem;
      height: 0.125rem;
    }
    &.right-right {
      right: -0.125rem;
      bottom: -0.125rem;
      height: 2rem;
      width: 0.125rem;
    }
  }
}
