







































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.slider-trail {
  height: 3px;
  border-radius: 1px;
  background-color: rgba(#4fc08d, 0.2);
}
