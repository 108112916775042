

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































#live-data {
  div.content {
    padding-top: 1rem;
    margin: 0 -6px;
    overflow: hidden;

    p.item {
      display: flex;
      width: 50%;
      float: left;

      .img {
        height: 2.5rem;
        width: 2.5rem;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 0.4rem;
      }

      .value {
        flex: 1;

        .title {
          font-size: 0.8rem;
          display: block;
          color: #999999;
        }

        .number {
          display: block;
          font-size: 1.6rem;
          color: #ffffff;
        }
      }

      &:first-of-type {
        .img {
          background-image: url(../../assets/01.png);
        }
      }

      &:nth-of-type(2) {
        .img {
          background-image: url(../../assets/02.png);
        }
      }

      &:nth-of-type(3) {
        .img {
          background-image: url(../../assets/03.png);
        }
      }

      &:nth-of-type(4) {
        .img {
          background-image: url(../../assets/04.png);
        }
      }

      &:nth-of-type(5) {
        .img {
          background-image: url(../../assets/05.png);
        }
      }

      &:nth-of-type(6) {
        .img {
          background-image: url(../../assets/06.png);
        }
      }
    }
  }
}

#equipment-status {
  .content {
    background: url('../../assets/shebei.png') no-repeat 4px 8px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 90px;
    height: 5.375rem;
    font-size: 0.875rem;

    p {
      line-height: 1.5rem;
    }
  }
}

#people-warning-list {
  .warning-item {
    margin-bottom: 0.75rem;
    padding: 8px 6px;
    font-size: 0.75rem;
    line-height: 1.5rem;
    background-color: #1e2c61;
    border-radius: 0.25rem;
    color: #ffffff;
    position: relative;
    display: flex;
    div.name {
      color: #999999;
      width: 2rem;
    }
    div.value {
      width: 3.7rem;
      padding-left: 0.1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    time {
      background-color: #577acc;
      border-radius: 4px;
      padding: 5px;
    }
  }
}
.linebar{
  display: flex;
  align-items: center;
  margin: 1.3rem auto 10px;
  font-size: 12px;
}
.line-bar{
  height: 5px;
  background-color: #344A7A;
  margin-right: 10px;
}
.real-bar{
  height: 5px;
  background-color: #57C7B6;
  border-radius: 12px;
}
.flag{
  display: inline-block;
  background-color: #57C7B6;
  color: #fff;
  width: 1.2rem;
  height: 1.2rem;
  line-height: 1.2rem;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
}
.panells{
  display:flex;
  align-items:center;
  justify-content: space-between;
  flex-wrap:wrap;
}
.panel{
  width: 49%;
  height: 14rem;
  background-color: #031A46;
  padding: 1rem;
  margin-bottom: 1rem;
}
