























































































































































































































































































































































































.empty-chart-icon {
  width: 10rem;
  height: 8rem;
  background: url("../../assets/empty_chart.png") no-repeat;
  background-size: contain;
}
.popup-bg {
  background-image: url("../../assets/stats-bg.png");
}

.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
}
.info-block {
  position: relative;
  width: 100%;
  background: url("../../assets/nation/title.png") no-repeat;
  background-size: contain;
}

.info-block-title {
  position: absolute;
  color: #c8dbf4;
  font-weight: bold;
  left: 2.8rem;
  top: 0.6rem;
}

.option-block {
  color: #919192;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 6rem;
  border: 1px solid #0d2b50;
  text-align: center;
  cursor: pointer;
}

.option-block-selected {
  color: #fcfcfc;
  border-color: #4999d9;
}

.slider-trail {
  height: 3px;
  border-radius: 1px;
  background-color: rgba(#4fc08d, 0.2);
}

.detail {
  background-color: #4191e5;
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3rem;
  text-align: center;
  cursor: pointer;
}


.el-picker-panel {
  color: #eee !important;
  border: 1px solid #121820 !important;
  -webkit-box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  background: #222933 !important;
}
 .el-date-table th {
  color: #eee !important;
  border-bottom: solid 1px #121820 !important;
}
 .el-popper[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: #222933 !important;
}
 .el-date-table td.start-date div,
.el-date-table td.end-date div {
  color: #222933 !important;
}
.el-date-table td.start-date span,
.el-date-table td.end-date span {
  background-color: #00adff !important;
}
 .el-date-table td.in-range div {
  background-color: #121820 !important;
}
.el-picker-panel__icon-btn {
  color: #ccc !important;
}
.icons{
  width: 61px;
  height: 61px;
}
