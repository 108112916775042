.building-list-enter-active {
  animation: trans-max-height 0.5s;
}
.building-list-leave-active,
.building-list-leave-to {
  animation: trans-max-height 0.8s reverse;
}
@keyframes trans-max-height {
  0% {
    max-height: 0;
  }
  50% {
    max-height: 0.8rem;
  }
  100% {
    max-height: 15rem;
  }
}
.__changer {
  text-align: center;
  position: relative;
}
.__changer .__changer__button {
  box-shadow: 0 0 24px 0 #21c1f5;
  background: #08122b;
  font-size: 0.9rem;
  cursor: pointer;
  color: #5accf1;
  margin-left: 10px;
}
.__changer .__changer__ul {
  z-index: -1;
  max-height: 15rem;
  position: absolute;
  transition: max-height 0.5s;
  border: 0.06rem solid #5F7EC9;
  bottom: 1.5rem;
  padding-top: 0.8rem;
  overflow-y: scroll;
  background: #08122b;
  border-radius: 5px;
  margin-left: 10px;
}
.__changer .__changer__ul::-webkit-scrollbar {
  width: 0;
}
.__changer .__changer__ul .__changer__li {
  cursor: pointer;
}
.__changer .__changer__ul .__changer__li.active {
  color: #5accf1;
}
.__changer .__changer_hide {
  max-height: 0;
}
