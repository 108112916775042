
















































































































































































































#patrol-mapid {
  width: 100%;
  height: 100vh;
}

.popup-bg {
  background-image: url("../../assets/stats-bg.png");
}

.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
}
