.jtimeline, .jtimeline * {
  box-sizing: border-box;
}

.jtimeline {
  max-width: 100%;
  position: relative;
  padding: 60px 200px;
  overflow: hidden;
}
.jtimeline .jtimeline-scroll-left,
.jtimeline .jtimeline-scroll-right {
  position: absolute;
  cursor: pointer;
  z-index: 20;
}
.jtimeline .jtimeline-scroll-left > .jtimeline-arrow,
.jtimeline .jtimeline-scroll-right > .jtimeline-arrow {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -20px;
  background-color: #555;
  color: #fff;
  border-radius: 999px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25);
}
.jtimeline .jtimeline-scroll-left.is-disabled,
.jtimeline .jtimeline-scroll-right.is-disabled {
  opacity: 0.25;
  cursor: not-allowed;
}
.jtimeline .jtimeline-scroll-left {
  left: 10px;
}
.jtimeline .jtimeline-scroll-right {
  right: 10px;
}
.jtimeline ul.jtimeline-events {
  min-width: 100%;
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  transform: translate(0, 0);
  transition: 0.5s ease-in-out;
}
.jtimeline ul.jtimeline-events:before {
  content: "";
  display: block;
  width: 50000px;
  height: 1px;
  background-color: #1e79c0;
  position: relative;
  left: -25000px;
}
.jtimeline ul.jtimeline-events > li.jtimeline-event {
  display: block;
  position: absolute;
  z-index: 10;
  cursor: pointer;
}
.jtimeline ul.jtimeline-events > li.jtimeline-event > .jtimeline-event-bullet {
  display: block;
  width: 16px;
  height: 16px;
  background-color: #151937;
  border-radius: 9999px;
  border-width: 2px;
  border-color: #1e79c0;
  border-style: solid;
  position: relative;
  top: -9px;
}
.jtimeline ul.jtimeline-events > li.jtimeline-event > .jtimeline-event-label {
  position: absolute;
  left: -90px;
  width: 200px;
  height: 100px;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: transparent;
}
.jtimeline ul.jtimeline-events > li.jtimeline-event:nth-child(odd) > .jtimeline-event-label {
  top: -126.5px;
  align-items: flex-end;
}
.jtimeline ul.jtimeline-events > li.jtimeline-event:nth-child(even) > .jtimeline-event-label {
  align-items: flex-start;
}
.jtimeline ul.jtimeline-events > li.jtimeline-event:hover, .jtimeline ul.jtimeline-events > li.jtimeline-event.is-active {
  z-index: 20;
}
.jtimeline ul.jtimeline-events > li.jtimeline-event:hover > .jtimeline-event-bullet, .jtimeline ul.jtimeline-events > li.jtimeline-event.is-active > .jtimeline-event-bullet {
  border-color: #1e79c0;
  background-color: #1e79c0;
}
.jtimeline ul.jtimeline-events > li.jtimeline-event:hover > .jtimeline-event-label:after, .jtimeline ul.jtimeline-events > li.jtimeline-event.is-active > .jtimeline-event-label:after {
  background-color: #1e79c0;
}

/*# sourceMappingURL=jtimeline.css.map */

