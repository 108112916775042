

















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.mon-change {
  display: flex;
  margin: 1rem;
  justify-content: space-between;
  align-items: center;
}
.mon-item {
  padding: 0.5rem;
  border: 1px solid #52b3ff;
  border-radius: 4px;
}
.active-mon {
  background-color: #52b3ff;
}
.img-left {
  position: absolute;
  top: 5rem;
  left: 3rem;
  width: 3rem;
  z-index: 9;
  cursor: pointer;
}
.img-right {
  position: absolute;
  top: 5rem;
  right: 3rem;
  width: 3rem;
  z-index: 9;
  cursor: pointer;
}
.img {
  width: 2.5rem;
  cursor: pointer;
}
.attendance-bottom {
  margin: 0 2rem;
}
.tooltips {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  // margin-bottom: 1rem;
  font-size: 0.6rem;
  color: #abbbbf;
  font-weight: bold;
}
.items {
  display: flex;
  align-items: center;
}
.tooltip-item {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.3rem;
}
.tooltip-item1 {
  background-color: #23cf9e;
}
.tooltip-item2 {
  background-color: #eab91e;
}
.tooltip-item3 {
  background-color: #e02f37;
}
.linebar {
  width: 100%;
}
.line-bar {
  display: flex;
  height: 5px;
  background-color: #0c1931;
  margin-right: 10px;
}
.real-bar {
  height: 5px;
  background-color: #4ebb8c;
  border-radius: 12px;
}
.slider-trail {
  height: 3px;
  border-radius: 1px;
  background-color: rgba(#4fc08d, 0.2);
}
