





































































































.v-number {
  overflow: hidden;
  position: relative;
  display: inline-block;

  &__original-value {
    visibility: hidden;
  }
  &__animated {
    position: absolute;
    transition-property: top;
    transition-timing-function: ease-in-out;
  }
  &-new-value {
    &-enter {
      top: -100%;
      &-to {
        top: 0;
      }
    }
  }
  &-current-value {
    &-leave {
      top: 0;
      &-to {
        top: 100%;
      }
    }
  }
  &-new-value2 {
    &-enter {
      top: 100%;
      &-to {
        top: 0;
      }
    }
  }
  &-current-value2 {
    &-leave {
      top: 0;
      &-to {
        top: -100%;
      }
    }
  }}
