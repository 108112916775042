





























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.now {
  width: 9rem;
  border: 1px solid #224d7e;
  padding: 0.8rem 0;
  color: #fff;
  background-color: rgba(34, 77, 126, 0.2);
  text-align: center;
  margin-right: 1rem;
}
.slider-trail {
  height: 3px;
  border-radius: 1px;
  background-color: rgba(#4fc08d, 0.2);
}
.linebar {
  width: 100%;
}
.line-bar {
  display: flex;
  height: 5px;
  background-color: #0c1931;
  margin-right: 10px;
}
.real-bar {
  height: 5px;
  background-color: #57c7b6;
  border-radius: 12px;
}
