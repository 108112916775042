








































#path {
  background-size: cover;
}

#map {
  .project_map {
    position: relative;
    background: none;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;
  }
}

#mapid {
  width: 100%;
  height: 100vh;
}

.map-angle {
  background-color: red;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.leaflet-popup-content-wrapper {
  background-color: #122040;
  text-align: center;
  border: 2px solid #5f7ec9;
}

.leaflet-popup-content > div:not(:last-child) {
  margin-bottom: 5px;
}

.leaflet-popup-tip-container {
  display: none;
}

