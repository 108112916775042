




































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// .el-carousel__button{
//   background-color: #222933;
// }
// .el-carousel__arrow{
//   color: #222933 !important;
// }
.el-carousel {
  height: 100% !important;
}
.el-carousel__container {
  height: 100% !important;
}
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
