



































































































































































































































































































































































































































































































































































































































































































.option-block-selected {
  color: #fcfcfc;
  background-color: #4999d9 !important;
  border: 1px solid #4999d9 !important;
}
.option-block{
  border: 1px solid #4999d9 !important;
}
.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
}
