



























































































































































































































































































































































































































































































































































































#heatmapid {
  width: 100%;
  height: 100vh;
}

.media-slider {
  height: 20px;
  margin-top: 10px;
}
.slider-trail {
  height: 2px;
  border-radius: 1px;
  background-color: rgba(#4fc08d, 0.2);
}
.handle {
  position: absolute;
  top: 50%;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin-top: -6px;
  margin-left: -6px;
  background-color: #75e6f5;
}

.qiehuan-button {
  background: url("../../assets/qiehuan2.png") no-repeat;
  width: 10rem;
  cursor: pointer;
  line-height: 16px;
  background-size: 100% 100%;
  text-align: center;
  padding: 5px;
}

.item-button {
  background-color: #181649;
  margin-top: -1px;
  border: 1px solid #365c83;
  width: 10rem;
  cursor: pointer;
  line-height: 16px;
  text-align: center;
  padding: 5px;
}
.item-button:first-child {
  margin-top: 0;
}
