.map_list {
  z-index: 999;
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: 0px;
  display: flex;
}
#main {
  display: flex;
  padding: 4.75rem 1.875rem 3.5rem 1.875rem;
  height: calc(100vh - 2rem);
  overflow-y: scroll;
}
#main #middle {
  width: 85rem;
  position: relative;
  padding: 0 1rem;
}
#main #middle #map .project_map {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
}
#mapid {
  width: 100%;
  height: 100vh;
}
