










































































































































































































































































































































































































































































































#attendance {
  font-family:Tahoma,Verdana,STHeiTi,simsun,sans-serif;
}
#title {
  line-height: 6rem;
  height: 6rem;
  margin-bottom: -4rem;
  background: url("../../assets/up.png") no-repeat transparent;
  background-size: contain;
  position: relative;
}
.date{
  display: flex;
  position: absolute;
  font-size: 1.2rem;
  font-weight: 500;
  top: -25%;
  left: 3%;
}
.project{
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#main {
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0 5rem 0;
  height: calc(100vh - 2rem);
  overflow-y: scroll;

  #top {
    display: flex;
    height: 22rem;
    margin-top: 1.5rem;
    justify-content: space-between;
  }

  #bottom {
    display: flex;
    height: 30rem;
    // margin-top: 2rem;
    justify-content: space-between;
    #equipment-status {
      .content {
        background: url("../../assets/shebei.png") no-repeat 4px 8px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-left: 90px;
        height: 5.375rem;
        font-size: 0.875rem;

        p {
          line-height: 1.5rem;
        }
      }
    }

    #people-status {
      .content {
        display: flex;
        padding: 1rem 0 .5rem;

        .left {
          width: 45%;
          text-align: center;

          .score {
            padding: 0.4rem 0;
            font-size: 1.875rem;
            color: #1fc86b;
          }

          .name {
            font-size: 0.875rem;
          }
        }

        .right {
          flex: 1;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          font-size: 0.875rem;
        }
      }
    }
  }

}

#logo {
  position: absolute;
  right: -1rem;
  bottom: 1.5rem;
  // align-items: center;
  line-height: 1.5rem;
  .watermark{
    vertical-align:text-bottom;
    width:24%;
    height:16%
  }
  span {
    font-size: 0.85rem;
    font-weight: bold;
    color: rgba(255, 255, 255, .6);
    margin-right: 1rem;
  }
}
