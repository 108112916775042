





















































































































































































































































































































































































































































































































































































































































































































































































































































.slider-trail {
  height: 3px;
  border-radius: 1px;
  background-color: rgba(#4fc08d, 0.2);
}
.linebar{
  display: flex;
  align-items: center;
  margin: 3px auto 12px;
  font-size: 12px;
}
.line-bar{
  height: 5px;
  background-color: #0C1931;
  margin-right: 10px;
}
.real-bar{
  height: 5px;
  background-color: #57C7B6;
  border-radius: 12px;
}
.option-block2 {
  color: #919192;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3.4rem;
  border: 1px solid #1b8ebd;
  text-align: center;
  cursor: pointer;
}
.option-block-selected2 {
  background-color: #4191e5;
  color: #fff;
}
