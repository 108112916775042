.container-title {
  display: flex;
  align-items: baseline;
}
.container-title .text {
  width: fit-content;
  margin-right: 0.3rem;
}
.container-title .dot {
  width: 48px;
  height: 12px;
  margin-right: 0.25rem;
  display: inline-block;
  background: url("../assets/bar.png");
}
.container-title .line {
  flex: 1;
  border-bottom: 0.125rem solid #5f7ec9;
}
