










































































































































































































































































































































.slider-trail {
  height: 3px;
  border-radius: 1px;
  background-color: rgba(#4fc08d, 0.2);
}
.case{
  width: 200px;
  text-align: center;
  height: 100px;
  line-height: 50px;
  border: 1px solid #fff;
  margin-bottom: 20px;
  font-size: 14px;
  color: #fff;
}
.num{
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
}
