.show_alarm_sure {
  margin-top: 20px;
  font-size: 15px;
  background-color: #3182ff;
  border-radius: 16px;
  width: 80px;
  height: 32px;
  line-height: 32px;
  margin-left: 120px;
}
.show_alarm_content {
  font-family: NotoSansHans-Medium;
  font-size: 14px;
  color: #ffffff;
  line-height: 23px;
}
.show_alarm_img {
  width: 185px;
  height: 135px;
  margin-top: 23px;
}
.mantle {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 999;
}
.show_alarm_title {
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  font-family: NotoSansHans-Bold;
  margin-top: 23px;
}
.show_alarm {
  text-align: center;
  top: 50%;
  position: absolute;
  border: 1px solid #151c48;
  left: 50%;
  height: 340px;
  margin-top: -160px;
  width: 320px;
  margin-left: -170px;
  z-index: 1000;
  background-color: #151c48;
  border-radius: 4px;
  opacity: 0.8;
  box-shadow: 0 0 0.2em #ffffff;
}
.build_list {
  z-index: 999;
  position: absolute;
  top: 0px;
  right: 5px;
  max-height: 93%;
  overflow-y: scroll;
  padding-right: 5px;
}
.pline {
  background-color: #399a75;
  border-radius: 24px;
  border: none;
  color: #fff;
  padding: 3px 10px;
  cursor: pointer;
  outline: none;
  margin-right: 8px;
}
.map_list {
  z-index: 999;
  position: absolute;
  background-color: #21315b;
  border: 1px solid #5e7ec9;
  cursor: pointer;
  align-items: center;
  display: flex;
  top: 0px;
  left: 0px;
}
.map_list > div {
  padding: 0.58rem 0.73rem;
  font-size: 0.82rem;
  border-right: 1px solid #5e7ec9;
}
.map_list .img {
  margin-left: 0.47rem;
  width: 0.76rem;
  height: 0.7rem;
}
.text-green {
  color: #1fc86b !important;
}
.text-yellow1 {
  color: #f89132 !important;
}
.text-yellow2 {
  color: #eebc34 !important;
}
.text-red1 {
  color: #e62c31 !important;
}
.text-red2 {
  color: #f34e53 !important;
}
.text-grey {
  color: #9ca0aa !important;
}
#project {
  background-size: cover;
}
.building-link,
.building-link:active,
.building-link:link,
.building-link:hover,
.building-link:visited {
  color: #51bbf5;
}
.building-info {
  margin-top: 5px;
  border: 1px solid #5f7ec9;
  padding: 0.5rem;
  z-index: 1;
  border-radius: 0.5rem;
  background: #122040;
}
.building-info h3 {
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin: 0;
}
.building-info p {
  display: flex;
  font-size: 0.875rem;
  line-height: 1.375rem;
}
.building-info p .title {
  width: 7rem;
}
.building-info p .value {
  width: 4rem;
  text-align: right;
}
.building-info-other {
  border: 1px solid #5f7ec9;
  padding: 0.23rem;
  z-index: 1;
  display: inline-block;
  position: absolute;
  background: #122040;
  border-radius: 50%;
  opacity: 0.1;
}
.building-info-other h3 {
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin: 0;
}
.building-info-other p {
  display: flex;
  font-size: 0.875rem;
  line-height: 1.375rem;
}
.building-info-other p .title {
  width: 7rem;
}
.building-info-other p .value {
  width: 4rem;
  text-align: right;
}
#system-info {
  position: fixed;
  top: 3rem;
  padding: 0 3rem;
  left: 1.875rem;
  width: 17.5rem;
  display: flex;
}
#system-info .item {
  position: relative;
  flex: 1;
  text-align: center;
}
#system-info .item .upper {
  font-size: 1.25rem;
}
#system-info .item .lower {
  font-size: 0.75rem;
  color: #cccccc;
  line-height: 1rem;
}
#system-info .left::after,
#system-info .middle::after {
  content: ' ';
  width: 0.125rem;
  height: 0.625rem;
  background-color: #244871;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}
#system-info .right {
  display: flex;
  justify-content: center;
}
#main {
  display: flex;
  padding: 4.75rem 1.875rem 3.5rem 1.875rem;
  height: calc(100vh - 2rem);
  overflow-y: scroll;
}
#main #left {
  width: 17.5rem;
}
#main #left #project-base-info p {
  font-size: 0.875rem;
  line-height: 1.875rem;
  display: flex;
}
#main #left #project-base-info p .title {
  width: 5.3rem;
  color: #5accf1;
}
#main #left #project-base-info p .value {
  flex: 1;
  text-align: right;
  color: #ffffff;
}
#main #left #people-warning-list {
  margin-right: -8px;
}
#main #left #people-warning-list .warning-item {
  margin-bottom: 0.75rem;
  padding: 14px 20px 14px 12px;
  font-size: 0.75rem;
  line-height: 1.5rem;
  background-color: #151c48;
  border-radius: 0.25rem;
  color: #ffffff;
  position: relative;
  display: flex;
}
#main #left #people-warning-list .warning-item span.name {
  color: #999999;
}
#main #left #people-warning-list .warning-item time {
  position: absolute;
  right: 0;
  background-color: #162e70;
  border-radius: 4px;
  top: 0;
  padding: 5px;
}
#main #middle {
  flex: 1;
  position: relative;
  padding: 0 1rem;
}
#main #middle #map .project_map {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
}
#main #middle #map .menus {
  z-index: 999;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #21315b;
  border: 1px solid #5e7ec9;
  cursor: pointer;
}
#main #middle #map .menus > div {
  padding: 0.58rem 0.73rem;
  font-size: 0.82rem;
}
#main #middle #map .menus > div:first-child {
  border-right: 1px solid #5e7ec9;
}
#main #middle #map .menus .img {
  margin-left: 0.47rem;
  width: 0.76rem;
  height: 0.7rem;
}
#main #middle #middle-charts {
  margin-top: 1rem;
  flex: 1;
  display: flex;
  justify-content: space-between;
}
#main #middle #middle-charts .danger-source-statistics .content {
  height: 13.125rem;
  padding-top: 1.5rem;
  display: flex;
}
#main #middle #middle-charts .danger-source-statistics .content .left {
  text-align: center;
  flex: 1;
}
#main #middle #middle-charts .danger-source-statistics .content .left .circle {
  border: 2px dotted #df6921;
}
#main #middle #middle-charts .danger-source-statistics .content .right {
  text-align: center;
  flex: 1;
}
#main #middle #middle-charts .danger-source-statistics .content .right .circle {
  border: 2px dotted #df2124;
}
#main #middle #middle-charts .danger-source-statistics .content .circle {
  margin-bottom: 1.25rem;
  width: 8.25rem;
  height: 8.25rem;
  border-radius: 50%;
  padding-top: 2rem;
}
#main #middle #middle-charts .danger-source-statistics .content .icon {
  width: 4rem;
  height: 4rem;
}
#main #right {
  width: 17.5rem;
}
#main #right #live-data div.content {
  padding: 2.5rem 0 2rem;
  margin: 0 -6px;
  overflow: hidden;
}
#main #right #live-data div.content p.item {
  display: flex;
  width: 50%;
  float: left;
}
#main #right #live-data div.content p.item .img {
  height: 3rem;
  width: 3rem;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 0.4rem;
}
#main #right #live-data div.content p.item .value {
  flex: 1;
}
#main #right #live-data div.content p.item .value .title {
  font-size: 0.8rem;
  display: block;
  color: #999999;
}
#main #right #live-data div.content p.item .value .number {
  display: block;
  font-size: 1.6rem;
  color: #ffffff;
}
#main #right #live-data div.content p.item:first-of-type .img {
  background-image: url(../../assets/01.png);
}
#main #right #live-data div.content p.item:nth-of-type(2) .img {
  background-image: url(../../assets/02.png);
}
#main #right #live-data div.content p.item:nth-of-type(3) .img {
  background-image: url(../../assets/03.png);
}
#main #right #live-data div.content p.item:nth-of-type(4) .img {
  background-image: url(../../assets/04.png);
}
#main #right #equipment-status .content {
  background: url('../../assets/shebei.png') no-repeat 4px 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 90px;
  height: 5.375rem;
  font-size: 0.875rem;
}
#main #right #equipment-status .content p {
  line-height: 1.5rem;
}
#main #right #people-status .content {
  display: flex;
  padding: 1rem 0 0.5rem;
}
#main #right #people-status .content .left {
  width: 45%;
  text-align: center;
}
#main #right #people-status .content .left .score {
  padding: 0.4rem 0;
  font-size: 1.875rem;
  color: #1fc86b;
}
#main #right #people-status .content .left .name {
  font-size: 0.875rem;
}
#main #right #people-status .content .right {
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-size: 0.875rem;
}
#logo {
  position: absolute;
  right: 2rem;
  bottom: 1rem;
  display: flex;
  align-items: center;
  line-height: 2rem;
}
#logo span {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.6);
  margin-right: 1rem;
}
#mapid {
  width: 100%;
  height: 100vh;
}
.map-angle {
  background-color: red;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.leaflet-popup-content-wrapper {
  background-color: #122040;
  text-align: center;
  border: 2px solid #5f7ec9;
}
.leaflet-popup-content > div:not(:last-child) {
  margin-bottom: 5px;
}
.leaflet-popup-tip-container {
  display: none;
}
.wxcallback {
  position: absolute;
  right: 2px;
  bottom: 2px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: cornflowerblue;
  cursor: pointer;
  border-radius: 13px;
}
