#project-base-info p {
  font-size: 0.875rem;
  line-height: 1.1rem;
  margin: 0.5rem 0;
  display: flex;
}
#project-base-info p .title {
  width: 4.6rem;
  color: #5accf1;
}
#project-base-info p .value {
  flex: 1;
  text-align: right;
  color: #ffffff;
}
#people-status #retreat-btn {
  margin: 1rem 0;
  height: 1.875rem;
  text-align: center;
  line-height: 1.875rem;
  cursor: pointer;
  background: linear-gradient(to right, #51bbf5, #5170f5);
}
#people-status .group {
  height: 31.2rem;
  overflow-y: scroll;
}
#people-status .group .group-item .group-title {
  margin-bottom: 1rem;
}
#people-status .group .group-item .group-title .group-title-icon {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  margin-right: 0.25rem;
  cursor: pointer;
  position: relative;
}
#people-status .group .group-item .group-title .group-title-icon.active {
  transform: rotate(-90deg);
}
#people-status .group .group-item .group-title .group-title-icon::before {
  content: " ";
  position: absolute;
  width: 1rem;
  font-size: 1rem;
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
  height: 1rem;
  background: url("../../assets/sanjiao.png") no-repeat;
}
#people-status .group .group-item .people-card-list .card-item {
  padding: 0.4rem 1rem;
  display: flex;
  margin-bottom: 0.75rem;
  background-color: #1e2c61;
  border-radius: 0.25rem;
}
#people-status .group .group-item .people-card-list .card-item .left {
  flex: 1;
  font-size: 0.88rem;
  line-height: 1.7rem;
}
#people-status .group .group-item .people-card-list .card-item .left .title {
  color: #999999;
}
#people-status .group .group-item .people-card-list .card-item .right {
  width: 3.8rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#people-status .group .group-item .people-card-list .card-item .right .status-card {
  border-width: 0.5rem 1px 1px;
  border-style: solid;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  padding: 0.7rem;
}
#people-status .group .group-item .people-card-list .card-item .right .status-card .zt {
  font-size: 0.88rem;
  color: #999;
  padding: 3px 0;
}
#people-status .group .group-item .people-card-list .card-item .right .status-card .status-text {
  padding-top: 5px;
  font-size: 1.13rem;
  font-weight: bold;
}
#people-status .group .group-item .people-card-list .card-item .right .op {
  width: 100%;
  height: 1.5rem;
  background: linear-gradient(90deg, #51bdf5, #5170f5);
  line-height: 1.5rem;
  cursor: pointer;
  font-size: 0.75rem;
  text-align: center;
}
#people-status .group .group-item .people-card-list .card-item .right .op.disabled {
  background: #8a8a8a;
  cursor: not-allowed;
}
.empty-chart-icon {
  width: 20.4rem;
  height: 15rem;
  background: url("../../assets/empty_chart.png") no-repeat;
  background-size: contain;
}
.stats-chart-block {
  height: 24.5rem;
  position: relative;
  border: 1px solid #334c88;
  background-image: url("../../assets/stats-bg.png");
}
.popup-bg {
  background-image: url("../../assets/stats-bg.png");
}
.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
}
.custom-select .selected {
  background-color: #0c1937;
  color: #12a0a2;
  font-weight: 300;
}
.custom-select .selected:after {
  border-color: #12a0a2 transparent transparent transparent;
}
.custom-select .items {
  color: #12a0a2;
  font-weight: 300;
  background-color: #0c1937;
}
.custom-select .items div {
  color: #12a0a2;
  font-weight: 300;
}
