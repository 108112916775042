.linebar {
  width: 92%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 1.3rem auto 10px;
  font-size: 12px;
}
.line-bar {
  height: 5px;
  background-color: #0c1931;
  margin-right: 10px;
}
.real-bar {
  height: 5px;
  background-color: #57c7b6;
  border-radius: 12px;
}
.text-green {
  color: #1fc86b !important;
}
.text-yellow1 {
  color: #f89132 !important;
  border-color: #f89132 !important;
}
.text-yellow2 {
  color: #eebc34 !important;
  border-color: #eebc34 !important;
}
.text-red1 {
  color: #e62c31 !important;
}
.text-red2 {
  color: #f34e53 !important;
}
.text-grey {
  color: #9ca0aa !important;
}
.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
}
#people-warning-list .warning-item {
  margin-bottom: 0.75rem;
  padding: 8px 6px;
  font-size: 0.75rem;
  line-height: 1.5rem;
  background-color: #1e2c61;
  border-radius: 0.25rem;
  color: #ffffff;
  position: relative;
  display: flex;
}
#people-warning-list .warning-item div.name {
  color: #999999;
  width: 2rem;
}
#people-warning-list .warning-item div.value {
  width: 3.7rem;
  padding-left: 0.1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#people-warning-list .warning-item time {
  background-color: #577acc;
  border-radius: 4px;
  padding: 5px;
}
