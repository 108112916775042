
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.detail {
  cursor: pointer;
  z-index: 9;
}
.image_list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  // justify-content: space-around;
  // align-content: space-around;
}
#content {
  margin-top: 0rem;
}
.screen-division {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
}
.division-item {
  width: 4rem;
  height: 2rem;
  text-align: center;
  line-height: 2rem;
  background: #21325c;
  border: 1px solid #5f7ec9;
  cursor: pointer;
}
.page-change {
  z-index: 999;
}
.active-division-type {
  color: #5accf1;
}
.content-title {
  z-index: 999;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  background-image: linear-gradient(to right, #0f1f3c, rgba(255, 255, 255, 0));
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
}
.board {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #000;
  padding-top: 3rem;
}
.no-video {
  position: absolute;
  top: 30%;
  left: 40%;
}

.top-item {
  height: 40rem;
  //   height: 39rem;

  width: calc(100vw - 4rem);
  display: flex;
}
.list-height {
  height: 40rem;
  padding-top: 3rem;
}
.people-status-ai {
  width: 23rem;
  margin-top: 1rem;
  margin-left: 0.5rem;
}
.chart_left {
  width: 23rem;
  margin-right: 0.5rem;
}
.big-content {
  width: calc(100vw - 50rem);
  height: 40rem;
  border: 1px solid #0d2b50;
  position: relative;
}
.bottom-item {
  height: 17rem;
  width: calc(100vw - 4rem);
  margin-top: 1rem;
}
.point-name {
  font-size: 16px;
  line-height: 1.8rem;
  font-weight: 400;
  color: #5accf1;
  margin-left: 1rem;
  text-decoration: underline;
}
.sure-click {
  cursor: pointer;
}
.one-screen {
  width: 100%;
  height: 100%;
}
.more-screen {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
}
.info-block-special {
  background-image: url("../../assets/new_block.png");
}
.dialog_img {
  width: 100%;
  height: 31.8rem;
  position: relative;
}
.image_info {
  width: 100%;
}
.img {
  width: 100%;
  height: 200px;
  cursor: pointer;
}
.img_all {
  width: 302px;
  height: 170px;
  cursor: pointer;
}
.swiper-c {
  padding-top: 3rem;
  height: 19rem;
}
.swiper {
  width: 90%;
  height: 100%;
}
.swiper-content {
  font-weight: bold;
}
.swiper-slide {
  cursor: pointer;
  width: 15rem;
  height: 100%;
  text-align: center;
  margin-right: 2rem;
  position: relative;

  .content-title {
    background-image: linear-gradient(to right, rgb(33, 58, 119), #0f1f3c);
  }
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 1rem;
  font-weight: bold;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  border: 1px solid;
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  border: 1px solid;
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
}
.swiper-button-prev,
.swiper-button-next {
  top: 50%;
  color: #fff;
}
