.mon-change {
  display: flex;
  margin: 1rem;
  justify-content: space-between;
  align-items: center;
}
.mon-item {
  padding: 0.5rem;
  border: 1px solid #52b3ff;
  border-radius: 4px;
}
.active-mon {
  background-color: #52b3ff;
}
.img {
  width: 2.5rem;
}
.table-title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  color: #fff;
}
.tab-item-first {
  width: 60%;
}
.tab-item {
  width: 20%;
  text-align: center;
}
.on {
  display: table-cell;
  position: relative;
}
.on a {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
}
.angle_top {
  content: "";
  width: 0;
  height: 0;
  display: block;
  border-style: solid;
  border-width: 0 5px 5px;
  border-color: transparent transparent #5e5e5e;
  position: absolute;
  transform: rotate(180deg);
  bottom: 0.5rem;
  right: 30%;
}
.angle_top_desc {
  content: "";
  width: 0;
  height: 0;
  display: block;
  border-style: solid;
  border-width: 0 5px 5px;
  border-color: transparent transparent #4191e5;
  position: absolute;
  transform: rotate(180deg);
  bottom: 0.5rem;
  right: 30%;
}
.angle_bottom {
  content: "";
  width: 0;
  height: 0;
  display: block;
  border-style: solid;
  border-width: 0 5px 5px;
  border-color: transparent transparent #5e5e5e;
  position: absolute;
  top: 0.6rem;
  right: 30%;
}
.angle_bottom_asc {
  content: "";
  width: 0;
  height: 0;
  display: block;
  border-style: solid;
  border-width: 0 5px 5px;
  border-color: transparent transparent #4191e5;
  position: absolute;
  top: 0.6rem;
  right: 30%;
}
.alarm-icon {
  width: 60px;
  height: 59px;
}
.option-detail {
  position: absolute;
  color: #c8dbf4;
  right: 0.5rem;
  top: 0.6rem;
  font-size: 0.6rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3rem;
  border: 1px solid #0d2b50;
  text-align: center;
  cursor: pointer;
  background-color: #4999d9;
}
#alarm-drop {
  background: url("../../assets/nation/坠落报警.png");
  background-size: contain;
}
#alarm-help {
  background: url("../../assets/nation/紧急呼救.png");
  background-size: contain;
}
#alarm-danger {
  background: url("../../assets/nation/危险预警.png");
  background-size: contain;
}
#alarm-static {
  background: url("../../assets/nation/静止报警.png");
  background-size: contain;
}
.alarm-icon-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
