









































































































































































































































.linebar {
  display: flex;
  align-items: baseline;
  margin: 1.3rem auto 10px;
  font-size: 12px;
}
.line-bar {
  height: 5px;
  background-color: #0c1931;
  margin-right: 10px;
  min-width: 12rem;
  border-radius: 12px;
}
.real-bar {
  height: 5px;
  background-color: #57c7b6;
  border-radius: 12px;
}
.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
}
