.wrapper {
  position: relative;
  padding: 0.8rem;
  /*background-color: #08122B;*/
  box-shadow: 0 0 12px 0 #21c1f5;
  border: solid 1px #5f7ec9;
  /*.right-top-corner {*/
  /*  width: 2.66rem;*/
  /*  height: 1px;*/
  /*  background-color: #ff5366;*/
  /*  position: absolute;*/
  /*  right: 0;*/
  /*  top: 0;*/
  /*  transform: rotate(45deg) translate(5%, 5%);*/
  /*}*/
}
.wrapper .decoration {
  position: absolute;
  background-color: #5accf1;
}
.wrapper .decoration.left-left {
  left: -0.125rem;
  top: -0.125rem;
  width: 2rem;
  height: 0.125rem;
}
.wrapper .decoration.left-top {
  left: -0.125rem;
  top: -0.125rem;
  height: 2rem;
  width: 0.125rem;
}
.wrapper .decoration.right-bottom {
  right: -0.125rem;
  bottom: -0.125rem;
  width: 2rem;
  height: 0.125rem;
}
.wrapper .decoration.right-right {
  right: -0.125rem;
  bottom: -0.125rem;
  height: 2rem;
  width: 0.125rem;
}
