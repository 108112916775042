.no_data_set {
  position: absolute;
  top: 30%;
  left: 45%;
}
.content-title {
  z-index: 99;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  background-image: linear-gradient(to right, #0f1f3c, rgba(255, 255, 255, 0));
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
}
.board {
  width: 100%;
  height: 100%;
}
.board-full {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
}
.detail-item {
  display: flex;
  height: 2.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  cursor: pointer;
}
.img-left {
  width: 26px;
  margin-right: 0.5rem;
  cursor: pointer;
}
.img-right {
  width: 26px;
  cursor: pointer;
}
.active-img-left {
  width: 15px;
  height: 16px;
  margin-right: 0.5rem;
}
.full-screen {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: 4rem;
  height: 2rem;
  line-height: 2rem;
  background-color: #21325c;
  border: 1px solid #5f7ec9;
  text-align: center;
  cursor: pointer;
}
.people-status {
  width: 23rem;
  margin-top: 1rem;
  margin-right: 0.5rem;
}
.project-files {
  width: 20rem;
  margin-top: 1rem;
  margin-right: 0.5rem;
}
.files-detail-tiltle {
  height: 2.5rem;
  line-height: 2.5rem;
  background: #0c2341;
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  color: #00ffff;
  padding: 0 1rem;
}
.top-item {
  height: 39rem;
  width: calc(100vw-2rem);
  display: flex;
}
.bottom-item {
  width: calc(100vw-2rem);
  height: 18rem;
  display: flex;
}
.right-item {
  display: flex;
  flex-direction: column;
  width: 23rem;
}
.hidden {
  display: none;
}
.big-content {
  width: calc(100vw - 27rem);
  height: 39rem;
  margin-right: 1rem;
  border: 1px solid #0d2b50;
  position: relative;
}
.big-content-full {
  height: 100%;
  width: 100%;
  margin: 0 0 0 -1.875rem;
  z-index: 20001;
}
.active-project {
  color: #40f8ff;
  background-color: #102855;
}
.mon-change {
  display: flex;
  margin: 1rem;
  justify-content: space-between;
  align-items: center;
}
.mon-item {
  padding: 0.5rem;
  border: 1px solid #52b3ff;
  border-radius: 4px;
}
.active-mon {
  background-color: #52b3ff;
}
.img {
  width: 2.5rem;
}
.table-title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  color: #fff;
}
.tab-item-first {
  width: 60%;
}
.tab-item {
  width: 20%;
  text-align: center;
}
.on {
  display: table-cell;
  position: relative;
}
.on a {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
}
.angle_top {
  content: "";
  width: 0;
  height: 0;
  display: block;
  border-style: solid;
  border-width: 0 5px 5px;
  border-color: transparent transparent #5e5e5e;
  position: absolute;
  transform: rotate(180deg);
  bottom: 0.5rem;
  right: 30%;
}
.angle_top_desc {
  content: "";
  width: 0;
  height: 0;
  display: block;
  border-style: solid;
  border-width: 0 5px 5px;
  border-color: transparent transparent #4191e5;
  position: absolute;
  transform: rotate(180deg);
  bottom: 0.5rem;
  right: 30%;
}
.angle_bottom {
  content: "";
  width: 0;
  height: 0;
  display: block;
  border-style: solid;
  border-width: 0 5px 5px;
  border-color: transparent transparent #5e5e5e;
  position: absolute;
  top: 0.6rem;
  right: 30%;
}
.angle_bottom_asc {
  content: "";
  width: 0;
  height: 0;
  display: block;
  border-style: solid;
  border-width: 0 5px 5px;
  border-color: transparent transparent #4191e5;
  position: absolute;
  top: 0.6rem;
  right: 30%;
}
.alarm-icon {
  width: 60px;
  height: 59px;
}
.option-detail {
  position: absolute;
  color: #c8dbf4;
  right: 0.5rem;
  top: 0.6rem;
  font-size: 0.6rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3rem;
  border: 1px solid #0d2b50;
  text-align: center;
  cursor: pointer;
  background-color: #4999d9;
}
#alarm-drop {
  background: url("../../assets/nation/坠落报警.png");
  background-size: contain;
}
#alarm-help {
  background: url("../../assets/nation/紧急呼救.png");
  background-size: contain;
}
#alarm-danger {
  background: url("../../assets/nation/危险预警.png");
  background-size: contain;
}
#alarm-static {
  background: url("../../assets/nation/静止报警.png");
  background-size: contain;
}
.alarm-icon-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
