








































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.show_alarm_sure {
  margin-top: 20px;
  font-size: 15px;
  background-color: #3182ff;
  border-radius: 16px;
  width: 80px;
  height: 32px;
  line-height: 32px;
  margin-left: 120px;
}
.show_alarm_content {
  font-family: NotoSansHans-Medium;
  font-size: 14px;
  color: #ffffff;
  line-height: 23px;
}
.show_alarm_img {
  width: 185px;
  height: 135px;
  margin-top: 23px;
}
.mantle {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 999;
}
.show_alarm_title {
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  font-family: NotoSansHans-Bold;
  margin-top: 23px;
}
.show_alarm {
  text-align: center;
  top: 50%;
  position: absolute;
  border: 1px solid #151c48;
  left: 50%;
  height: 340px;
  margin-top: -160px;
  width: 320px;
  margin-left: -170px;
  z-index: 1000;
  background-color: #151c48;
  border-radius: 4px;
  opacity: 0.8;
  box-shadow: 0 0 0.2em #ffffff;
}
.build_list {
  z-index: 999;
  position: absolute;
  top: 0px;
  right: 5px;
  max-height: 93%;
  overflow-y: scroll;
  padding-right: 5px;
}
.pline {
  background-color: #399a75;
  border-radius: 24px;
  border: none;
  color: #fff;
  padding: 3px 10px;
  cursor: pointer;
  outline: none;
  margin-right: 8px;
}

.map_list {
  z-index: 999;
  position: absolute;
  background-color: #21315b;
  border: 1px solid #5e7ec9;
  cursor: pointer;
  align-items: center;
  display: flex;
  top: 0px;
  left: 0px;
  > div {
    padding: 0.58rem 0.73rem;
    font-size: 0.82rem;
    border-right: 1px solid #5e7ec9;
  }

  .img {
    margin-left: 0.47rem;
    width: 0.76rem;
    height: 0.7rem;
  }
}

.text-green {
  color: #1fc86b !important;
}

.text-yellow1 {
  color: #f89132 !important;
}

.text-yellow2 {
  color: #eebc34 !important;
}

.text-red1 {
  color: #e62c31 !important;
}

.text-red2 {
  color: #f34e53 !important;
}

.text-grey {
  color: #9ca0aa !important;
}

#project {
  background-size: cover;
}

.building-link,
.building-link:active,
.building-link:link,
.building-link:hover,
.building-link:visited {
  color: #51bbf5;
}

.building-info {
  margin-top: 5px;
  border: 1px solid #5f7ec9;
  padding: 0.5rem;
  z-index: 1;
  border-radius: 0.5rem;
  // display: inline-block;
  // position: absolute;
  // background-image: url("../../assets/bg1.png");
  background: #122040;

  h3 {
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin: 0;
  }

  p {
    display: flex;
    font-size: 0.875rem;
    line-height: 1.375rem;

    .title {
      width: 7rem;
    }

    .value {
      width: 4rem;
      text-align: right;
    }
  }
}

.building-info-other {
  border: 1px solid #5f7ec9;
  padding: 0.23rem;
  z-index: 1;
  display: inline-block;
  position: absolute;
  background: #122040;
  border-radius: 50%;
  opacity: 0.1;

  h3 {
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin: 0;
  }

  p {
    display: flex;
    font-size: 0.875rem;
    line-height: 1.375rem;

    .title {
      width: 7rem;
    }

    .value {
      width: 4rem;
      text-align: right;
    }
  }
}

#system-info {
  position: fixed;
  top: 3rem;
  padding: 0 3rem;
  left: 1.875rem;
  width: 17.5rem;
  display: flex;

  .item {
    position: relative;
    flex: 1;
    text-align: center;

    .upper {
      font-size: 1.25rem;
    }

    .lower {
      font-size: 0.75rem;
      color: #cccccc;
      line-height: 1rem;
    }
  }

  .left::after,
  .middle::after {
    content: ' ';
    width: 0.125rem;
    height: 0.625rem;
    background-color: #244871;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }

  .right {
    display: flex;
    justify-content: center;
  }
}

#main {
  display: flex;
  padding: 4.75rem 1.875rem 3.5rem 1.875rem;
  height: calc(100vh - 2rem);
  overflow-y: scroll;

  #left {
    width: 17.5rem;

    #project-base-info {
      p {
        font-size: 0.875rem;
        line-height: 1.875rem;
        display: flex;

        .title {
          width: 5.3rem;
          color: #5accf1;
        }

        .value {
          flex: 1;
          text-align: right;
          color: #ffffff;
        }
      }
    }

    #people-warning-list {
      margin-right: -8px;
      .warning-item {
        margin-bottom: 0.75rem;
        padding: 14px 20px 14px 12px;
        font-size: 0.75rem;
        line-height: 1.5rem;
        background-color: #151c48;
        border-radius: 0.25rem;
        color: #ffffff;
        position: relative;
        display: flex;
        span.name {
          color: #999999;
        }
        time {
          position: absolute;
          right: 0;
          background-color: #162e70;
          border-radius: 4px;
          top: 0;
          padding: 5px;
        }
      }
    }
  }

  #middle {
    flex: 1;
    position: relative;
    padding: 0 1rem;

    #map {
      // margin-bottom: 1rem;

      .project_map {
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: transparent;
      }

      .menus {
        z-index: 999;
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #21315b;
        border: 1px solid #5e7ec9;
        cursor: pointer;

        > div {
          padding: 0.58rem 0.73rem;
          font-size: 0.82rem;
        }

        > div:first-child {
          border-right: 1px solid #5e7ec9;
        }

        .img {
          margin-left: 0.47rem;
          width: 0.76rem;
          height: 0.7rem;
        }
      }
    }

    #middle-charts {
      margin-top: 1rem;
      flex: 1;
      display: flex;
      justify-content: space-between;

      .danger-source-statistics {
        .content {
          height: 13.125rem;
          padding-top: 1.5rem;
          display: flex;

          .left {
            text-align: center;
            flex: 1;

            .circle {
              border: 2px dotted #df6921;
            }
          }

          .right {
            text-align: center;
            flex: 1;

            .circle {
              border: 2px dotted #df2124;
            }
          }

          .circle {
            margin-bottom: 1.25rem;
            width: 8.25rem;
            height: 8.25rem;
            border-radius: 50%;
            padding-top: 2rem;
          }

          .icon {
            width: 4rem;
            height: 4rem;
          }
        }
      }
    }
  }

  #right {
    width: 17.5rem;

    #live-data {
      div.content {
        padding: 2.5rem 0 2rem;
        margin: 0 -6px;
        overflow: hidden;

        p.item {
          display: flex;
          width: 50%;
          float: left;

          .img {
            height: 3rem;
            width: 3rem;
            background-repeat: no-repeat;
            background-size: cover;
            margin-right: 0.4rem;
          }

          .value {
            flex: 1;

            .title {
              font-size: 0.8rem;
              display: block;
              color: #999999;
            }

            .number {
              display: block;
              font-size: 1.6rem;
              color: #ffffff;
            }
          }

          &:first-of-type {
            .img {
              background-image: url(../../assets/01.png);
            }
          }

          &:nth-of-type(2) {
            .img {
              background-image: url(../../assets/02.png);
            }
          }

          &:nth-of-type(3) {
            .img {
              background-image: url(../../assets/03.png);
            }
          }

          &:nth-of-type(4) {
            .img {
              background-image: url(../../assets/04.png);
            }
          }
        }
      }
    }

    #equipment-status {
      .content {
        background: url('../../assets/shebei.png') no-repeat 4px 8px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-left: 90px;
        height: 5.375rem;
        font-size: 0.875rem;

        p {
          line-height: 1.5rem;
        }
      }
    }

    #people-status {
      .content {
        display: flex;
        padding: 1rem 0 0.5rem;

        .left {
          width: 45%;
          text-align: center;

          .score {
            padding: 0.4rem 0;
            font-size: 1.875rem;
            color: #1fc86b;
          }

          .name {
            font-size: 0.875rem;
          }
        }

        .right {
          flex: 1;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          font-size: 0.875rem;
        }
      }
    }
  }
}

#logo {
  position: absolute;
  right: 2rem;
  bottom: 1rem;
  display: flex;
  align-items: center;
  line-height: 2rem;

  span {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.6);
    margin-right: 1rem;
  }
}

#mapid {
  width: 100%;
  height: 100vh;
}

.map-angle {
  background-color: red;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.leaflet-popup-content-wrapper {
  background-color: #122040;
  text-align: center;
  border: 2px solid #5f7ec9;
}

.leaflet-popup-content > div:not(:last-child) {
  margin-bottom: 5px;
}

.leaflet-popup-tip-container {
  display: none;
}

.wxcallback{
  position:absolute;
  right: 2px;
  bottom: 2px;
  padding-left:12px;
  padding-right:12px;
  background-color: cornflowerblue;
  cursor: pointer;
  border-radius: 13px
}

// .btn1{
//     background-color:#399A75;
//     border-radius:24px;
//     border:none;
//     color:#fff;
//     padding: 3px 10px;
//     cursor: pointer;
//     outline: none;
//     margin-right: 8px;
// }

// .btn2{
//     background-color:#5186F5;
//     border-radius:24px;
//     border:none;
//     color:#fff;
//     padding: 3px 10px;
//     cursor: pointer;
//     outline: none;
// }
