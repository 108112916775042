#people-status #retreat-btn {
  margin: 1rem 0;
  height: 1.875rem;
  text-align: center;
  line-height: 1.875rem;
  cursor: pointer;
  background: linear-gradient(to right, #51bbf5, #5170f5);
}
#people-status .group {
  height: 28rem;
  padding: 0 1rem;
  overflow-y: scroll;
}
#people-status .group .group-item .group-title {
  margin-bottom: 1rem;
}
#people-status .group .group-item .group-title .group-title-icon {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  margin-right: 0.25rem;
  cursor: pointer;
  position: relative;
}
#people-status .group .group-item .group-title .group-title-icon.active {
  transform: rotate(-90deg);
}
#people-status .group .group-item .group-title .group-title-icon::before {
  content: " ";
  position: absolute;
  width: 1rem;
  font-size: 1rem;
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
  height: 1rem;
  background: url("../../assets/sanjiao.png") no-repeat;
}
#people-status .group .group-item .people-card-list .card-item {
  padding: 0.4rem 1rem;
  display: flex;
  margin-bottom: 0.75rem;
  background-color: #151c48;
  border-radius: 0.25rem;
}
#people-status .group .group-item .people-card-list .card-item .left {
  flex: 1;
  font-size: 14px;
  line-height: 2rem;
}
#people-status .group .group-item .people-card-list .card-item .left .title {
  color: #999999;
}
#people-status .group .group-item .people-card-list .card-item .right {
  width: 5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#people-status .group .group-item .people-card-list .card-item .right .status-card {
  border-width: 0.5rem 1px 1px;
  border-style: solid;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  padding: 0.7rem;
}
#people-status .group .group-item .people-card-list .card-item .right .status-card .zt {
  font-size: 0.88rem;
  color: #999;
  padding: 3px 0;
}
#people-status .group .group-item .people-card-list .card-item .right .status-card .status-text {
  padding-top: 5px;
  font-size: 1.13rem;
  font-weight: bold;
}
#people-status .group .group-item .people-card-list .card-item .right .op {
  width: 3rem;
  height: 1.5rem;
  background: linear-gradient(90deg, #51bdf5, #5170f5);
  line-height: 1.5rem;
  cursor: pointer;
  font-size: 0.75rem;
  text-align: center;
}
#people-status .group .group-item .people-card-list .card-item .right .op.disabled {
  background: #8a8a8a;
  cursor: not-allowed;
}
#people-status .group .group-item .people-card-list .card-item .right .op2 {
  margin-left: 0.5rem;
  background: linear-gradient(90deg, #f5c151, #ef873b);
}
.el-dialog.recall-dialog {
  width: 31.25rem !important;
  background-color: rgba(21, 28, 72, 0.8) !important;
}
.recall-dialog .el-dialog__header,
.recall-dialog .el-dialog__footer {
  display: none;
}
.recall-dialog .el-dialog__body {
  text-align: center;
  font-size: 1.25rem;
  color: #fff;
  padding: 1.5rem;
}
.recall-dialog .warning-icon {
  display: inline-block;
  background: url("../../assets/weixian.png") no-repeat;
  width: 5rem;
  height: 5rem;
  background-size: cover;
  margin-bottom: 3.5rem;
}
.recall-dialog .cancel-button,
.recall-dialog .confirm-button {
  width: 6.13rem;
  height: 2rem;
  border-radius: 1rem;
  color: #ffffff;
  line-height: 2rem;
  font-size: 1rem;
  display: inline-block;
  background: #7578a7;
  cursor: pointer;
  text-align: center;
}
.recall-dialog .confirm-button {
  margin-left: 2.5rem;
  background: linear-gradient(90deg, #51bdf5, #5170f5);
}
.text-green {
  color: #1fc86b !important;
}
.text-yellow1 {
  color: #f89132 !important;
  border-color: #f89132 !important;
}
.text-yellow2 {
  color: #eebc34 !important;
  border-color: #eebc34 !important;
}
.text-red1 {
  color: #e62c31 !important;
}
.text-red2 {
  color: #f34e53 !important;
}
.text-grey {
  color: #9ca0aa !important;
}
.borderColor-green {
  border-color: #1fc86b !important;
}
.borderColor-grey {
  border-color: #9ca0aa !important;
}
.borderColor-yellow1 {
  border-color: #f89132 !important;
}
.borderColor-yellow2 {
  border-color: #eebc34 !important;
}
.borderColor-red1 {
  border-color: #e62c31 !important;
}
.borderColor-red2 {
  border-color: #f34e53 !important;
}
.stats-close-btn {
  z-index: 30000;
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
}
#people-warning-list .warning-item {
  margin-bottom: 0.75rem;
  padding: 8px 6px;
  font-size: 0.75rem;
  line-height: 1.5rem;
  background-color: #151c48;
  border-radius: 0.25rem;
  color: #ffffff;
  position: relative;
  display: flex;
  justify-content: space-around;
}
#people-warning-list .warning-item div.name {
  color: #999999;
  width: 2rem;
}
#people-warning-list .warning-item div.value {
  width: 5rem;
  padding-left: 0.1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#people-warning-list .warning-item time {
  background-color: #577acc;
  border-radius: 4px;
  padding: 5px;
}
