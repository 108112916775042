





























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.show_alarm_sure {
  margin-top: 20px;
  font-size: 15px;
  background-color: #3182ff;
  border-radius: 16px;
  width: 80px;
  height: 32px;
  line-height: 32px;
  margin-left: 120px;
}
.show_alarm_content {
  font-family: NotoSansHans-Medium;
  font-size: 14px;
  color: #ffffff;
  line-height: 23px;
}
.show_alarm_img {
  width: 185px;
  height: 135px;
  margin-top: 23px;
}
.mantle {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 999;
}
.show_alarm_title {
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  font-family: NotoSansHans-Bold;
  margin-top: 23px;
}
.show_alarm {
  text-align: center;
  top: 50%;
  position: absolute;
  border: 1px solid #151c48;
  left: 50%;
  height: 340px;
  margin-top: -160px;
  width: 320px;
  margin-left: -170px;
  z-index: 1000;
  background-color: #151c48;
  border-radius: 4px;
  opacity: 0.8;
  box-shadow: 0 0 0.2em #ffffff;
}
.broadcast-items {
  top: 0;
  position: absolute;
  width: 50rem;
  z-index: 1;
}
.build_list {
  z-index: 1;
  position: absolute;
  top: 0px;
  right: 5px;
  max-height: 93%;
  overflow-y: scroll;
  padding-right: 5px;
}
.map_compass {
  z-index: 999;
  position: absolute;
  bottom: 30px;
  left: 30px;
  width: 54px;
  height: 54px;
}
#xunjian {
  width: 88px;
  height: 88px;
  background: url("../../assets/nation/巡检率.png");
  background-size: cover;
  position: absolute;
  z-index: 999;
  bottom: 13px;
  left: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
#chuqin {
  width: 88px;
  height: 88px;
  background: url("../../assets/nation/出勤率.png");
  background-size: cover;
  position: absolute;
  z-index: 999;
  bottom: 13px;
  left: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pline {
  background-color: #399a75;
  border-radius: 24px;
  border: none;
  color: #fff;
  padding: 3px 10px;
  cursor: pointer;
  outline: none;
  margin-right: 8px;
}

.map_list {
  z-index: 999;
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
}

.stats_btn {
  color: #fff;
  overflow: hidden;
  border-top: 1px solid #334c88;
  border-right: 1px solid #334c88;
  border-bottom: 1px solid #334c88;
  background-color: #2a4278;
  padding: 0 15px;
  line-height: 1.8rem;
  display: none;
}

.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
  z-index: 30000;
}

.zk-btn {
  background-image: url("../../assets/zk.png");
  width: 32px;
  height: 32px;
  cursor: pointer;
  z-index: 30000;
}

.zk-btn.zk-btn-sq {
  background-image: url("../../assets/sq.png");
}

.text-green {
  color: #1fc86b !important;
}

.text-yellow1 {
  color: #f89132 !important;
  border-color:#f89132 !important;
}

.text-yellow2 {
  color: #eebc34 !important;
  border-color:#eebc34 !important;
}

.text-red1 {
  color: #e62c31 !important;
}

.text-red2 {
  color: #f34e53 !important;
}

.text-grey {
  color: #9ca0aa !important;
}

.building-link,
.building-link:active,
.building-link:link,
.building-link:hover,
.building-link:visited {
  color: #51bbf5;
}

.building-info {
  margin-top: 5px;
  border: 1px solid #5f7ec9;
  padding: 0.5rem;
  z-index: 1;
  border-radius: 0.5rem;
  // display: inline-block;
  // position: absolute;
  // background-image: url("../../assets/bg1.png");
  background: #1e2c61;

  h3 {
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin: 0;
  }

  p {
    display: flex;
    font-size: 0.875rem;
    line-height: 1.375rem;

    .title {
      width: 5.5rem;
    }

    .value {
      width: 2.5rem;
      text-align: right;
    }
  }
}

.building-info-other {
  border: 1px solid #5f7ec9;
  padding: 0.23rem;
  z-index: 1;
  display: inline-block;
  position: absolute;
  background: #1e2c61;
  border-radius: 50%;
  opacity: 0.1;

  h3 {
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin: 0;
  }

  p {
    display: flex;
    font-size: 0.875rem;
    line-height: 1.375rem;

    .title {
      width: 7rem;
    }

    .value {
      width: 4rem;
      text-align: right;
    }
  }
}

.menu-block {
  position: fixed;
  top: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: none !important;
  pointer-events: none;

  .el-menu-item {
    pointer-events: all;
    padding: 0 12px;
    font-size: 15px;
    border-bottom: none;
    width: 120px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    background: url("../../assets/nation/button.png");
  }

  .el-menu-item.is-active {
    border-bottom: none;
    background: url("../../assets/nation/button_select.png");
  }

  .el-menu-item:not(.is-disabled):hover,
  .el-menu-item:not(.is-disabled):focus {
    border-bottom: none;
    background-color: transparent !important;
  }
}

#system-info {
  position: fixed;
  top: 1rem;
  padding: 0 3rem;
  left: -2rem;
  width: 9rem;
  display: flex;

  .item {
    position: relative;
    flex: 1;
    text-align: center;

    .upper {
      font-size: 1.25rem;
    }

    .lower {
      font-size: 0.75rem;
      color: #cccccc;
      line-height: 1rem;
    }
  }

  .left::after,
  .middle::after {
    content: " ";
    width: 0.125rem;
    height: 0.625rem;
    background-color: #244871;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }

  .right {
    display: flex;
    justify-content: center;
  }
}

.main-block {
  margin-top: 3.2rem;
  padding: 0rem 1.875rem 0.5rem 1.875rem;
  height: calc(100vh - 2rem);
  overflow-y: scroll;
}

.sub-menu {
  display: flex;
  margin-top: 0.3rem;
}

.sub-menu-item {
  cursor: pointer;
  border: 1px #1a96c4 solid;
  margin-left: 0.2rem;
  font-size: 0.85rem;
  padding: 0.3rem 0.8rem;
}

#main {
  display: flex;
  // padding: 4.75rem 1.875rem 3.5rem 1.875rem;
  padding: 0 1.875rem 3.5rem 1.875rem;
  margin-top: 4.75rem;
  height: calc(100vh - 10rem);
  overflow-y: scroll;

  #left {
    width: 26.5rem;
  }

  #right {
    width: 26.5rem;
  }

  #middle {
    width: 67rem;
    position: relative;
    padding: 0 1rem;
    margin: 0 auto;

    #map {
      // margin-bottom: 1rem;
      .project_map {
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: transparent;
      }

      .menus {
        z-index: 999;
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #21315b;
        cursor: pointer;

        > div {
          padding: 0.58rem 0.73rem;
          font-size: 0.82rem;
        }

        > div:first-child {
          border-right: 1px solid #5e7ec9;
        }

        .img {
          margin-left: 0.47rem;
          width: 0.76rem;
          height: 0.7rem;
        }
      }
    }
  }
}

#mapid {
  width: 100%;
  height: 100vh;
}

.map-angle {
  background-color: red;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.leaflet-popup-content-wrapper {
  background-color: #122040;
  text-align: center;
  border: 2px solid #5f7ec9;
}

.leaflet-popup-content > div:not(:last-child) {
  margin-bottom: 5px;
}

.leaflet-popup-tip-container {
  display: none;
}

  .borderColor-green {
    border-color: #1fc86b !important;
  }

  .borderColor-grey {
    border-color: #9ca0aa !important;
  }

  .borderColor-yellow1 {
    border-color: #F89132 !important;
  }

  .borderColor-yellow2 {
    border-color: #eebc34 !important;
  }

  .borderColor-red1 {
    border-color: #e62c31 !important;
  }

  .borderColor-red2 {
    border-color: #f34e53 !important;
  }

// .btn1{
//     background-color:#399A75;
//     border-radius:24px;
//     border:none;
//     color:#fff;
//     padding: 3px 10px;
//     cursor: pointer;
//     outline: none;
//     margin-right: 8px;
// }

// .btn2{
//     background-color:#5186F5;
//     border-radius:24px;
//     border:none;
//     color:#fff;
//     padding: 3px 10px;
//     cursor: pointer;
//     outline: none;
// }
