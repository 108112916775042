#tabs {
  background: #0e1328;
}
.tabbar {
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 40px;
  border: 1px solid #157EAB;
  width: 210px;
  margin: auto;
  cursor: pointer;
}
.tabbar div {
  flex: 1;
  text-align: center;
}
.tabar-select {
  background-color: #4191E5;
  font-weight: bold;
}
