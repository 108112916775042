.tooltips {
  width: 60%;
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  margin-bottom: 1rem;
  font-size: 0.6rem;
  color: #abbbbf;
  font-weight: bold;
}
.items {
  display: flex;
  align-items: center;
}
.tooltip-item {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.3rem;
}
.tooltip-item1 {
  background-color: #23cf9e;
}
.tooltip-item2 {
  background-color: #eab91e;
}
.tooltip-item3 {
  background-color: #e02f37;
}
