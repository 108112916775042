
































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.people-status-proj {
  .inline-input {
    margin: 1rem;
    width: 90% !important;
    .el-input__inner {
      background-color: #0c2341 !important;
      border: 1px solid #1782a6 !important;
      height: 2.5rem !important;
    }
  }
  .el-tree-node__content {
    color: #5accf1;
    background-color: #0f1f3c;
  }
}
.big-content {
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .btn-next {
    padding: 0;
    border: 1px solid #5f7ec9;
    background-color: #21325c;
    padding: 0 0.5rem;
    margin: 0;
  }
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .el-pager li {
    margin: 0;
    border: 1px solid #5f7ec9;
    background-color: #21325c;
    border-radius: 0;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #21325c;
    color: #5accf1;
  }
}
#main{
  // height: calc(100vh - 2rem) !important;
}
