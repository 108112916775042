









































































































































































































































































.detail-select {
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3rem;
  text-align: center;
  cursor: pointer;
}
.custom-select .selected {
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}

.custom-select .selected.open {
  border: 1px solid #49a0e6 !important;
}

.custom-select .items {
  border-right: 1px solid #49a0e6 !important;
  border-left: 1px solid #49a0e6 !important;
  border-bottom: 1px solid #49a0e6 !important;
  background-color: #0b1d39 !important;
}
