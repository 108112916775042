.inline-input .el-input__inner {
  background-color: #0c2341 !important;
  border: 1px solid #1782a6 !important;
  height: 2.5rem !important;
}
.el-autocomplete-suggestion {
  border: 0.5px solid #1782a6 !important;
  margin-top: 0 !important;
}
.el-autocomplete-suggestion__wrap {
  background-color: #0c2341 !important;
}
#logo {
  position: absolute;
  right: 2rem;
  bottom: 1.5rem;
  display: flex;
  align-items: center;
  line-height: 2rem;
}
#logo span {
  font-size: 1.125rem;
  color: rgba(255, 255, 255, 0.6);
  margin-right: 0.5rem;
}
