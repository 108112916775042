.inline-input .el-input__inner {
  background-color: #0c2341 !important;
  border: 1px solid #1782a6 !important;
  height: 2.5rem !important;
}
.el-autocomplete-suggestion {
  border: 0.5px solid #1782a6 !important;
  margin-top: 0 !important;
}
.el-autocomplete-suggestion__wrap {
  background-color: #0c2341 !important;
}
.stats-layer .el-dialog__header {
  display: none;
}
.stats-layer .el-dialog__body {
  padding: 0;
  color: unset;
}
