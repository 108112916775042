#admin {
  font-size: 16px;
}
#admin .global-floor-name {
  position: fixed;
  z-index: 1;
  left: 30px;
  top: 120px;
  font-size: 32px;
  color: #398EE3;
}
#admin header {
  background-color: #398EE3;
  font-size: 20px;
  font-weight: bold;
  padding-left: 30px;
  color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 60px;
  box-shadow: 0 2px 7px rgba(10, 33, 57, 0.39);
  line-height: 60px;
}
#admin #btn-change-building {
  position: fixed;
  width: 40px;
  cursor: pointer;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 1px solid #ECECEC;
  right: 24px;
  bottom: 60px;
  text-align: center;
  line-height: 46px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}
#admin #btn-change-building .building-list {
  position: absolute;
  right: 120%;
  bottom: -50%;
  padding: 5px;
  border-radius: 5px;
  width: max-content;
  background-color: #fff;
  border-bottom: 1px solid #D6D6D6;
}
#admin #btn-change-building .building-list li {
  padding: 5px;
  line-height: 15px;
  color: #000000;
  cursor: pointer;
  border-bottom: 1px solid #D6D6D6;
}
#admin #btn-change-building .building-list li:last-of-type {
  border-bottom: none;
}
#admin #btn-change-building .building-list li.active {
  color: #398EE3;
}
#admin #btn-change-floor {
  position: fixed;
  right: 16px;
  bottom: 120px;
  padding: 6px 8px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.7);
}
#admin #btn-change-floor .upward,
#admin #btn-change-floor .downward {
  cursor: pointer;
  border: solid transparent;
  border-width: 22px 16px;
  width: 0;
  height: 0;
}
#admin #btn-change-floor .upward {
  border-bottom-color: #398EE3;
  margin-bottom: 8px;
  border-top-width: 0;
}
#admin #btn-change-floor .downward {
  border-top-color: #398EE3;
  margin-top: 8px;
  border-bottom-width: 0;
}
#admin #btn-change-floor .floor-name {
  color: #398EE3;
  width: 32px;
  height: 16px;
  text-align: center;
  line-height: 16px;
}
.vux-uploader {
  padding: 0;
}
