

















































































































































































































































































































































































































































































































































































































































































































































































































































































































.show_alarm_sure {
  margin-top: 20px;
  font-size: 15px;
  background-color: #3182ff;
  border-radius: 16px;
  width: 80px;
  height: 32px;
  line-height: 32px;
  margin-left: 120px;
}
.show_alarm_content {
  font-family: NotoSansHans-Medium;
  font-size: 14px;
  color: #ffffff;
  line-height: 23px;
}
.show_alarm_img {
  width: 185px;
  height: 135px;
  margin-top: 23px;
}
.mantle {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 999;
}
.show_alarm_title {
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  font-family: NotoSansHans-Bold;
  margin-top: 23px;
}
.show_alarm {
  text-align: center;
  top: 50%;
  position: absolute;
  border: 1px solid #151c48;
  left: 50%;
  height: 340px;
  margin-top: -160px;
  width: 320px;
  margin-left: -170px;
  z-index: 1000;
  background-color: #151c48;
  border-radius: 4px;
  opacity: 0.8;
  box-shadow: 0 0 0.2em #ffffff;
}
.stats-label {
  margin: 10px 0 -3px 3px;
  font-size: 1rem;
  font-weight: bold;
  color: #cececf;
}
.build_list {
  z-index: 1;
  position: absolute;
  top: 0px;
  right: 5px;
  max-height: 93%;
  overflow-y: scroll;
  padding-right: 5px;
}
.map_compass {
  z-index: 999;
  position: absolute;
  bottom: 30px;
  left: 30px;
  width: 54px;
  height: 54px;
}
#xunjian {
  width: 88px;
  height: 88px;
  background: url("../../assets/nation/巡检率.png");
  background-size: cover;
  position: absolute;
  z-index: 999;
  bottom: 13px;
  left: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
#chuqin {
  width: 88px;
  height: 88px;
  background: url("../../assets/nation/出勤率.png");
  background-size: cover;
  position: absolute;
  z-index: 999;
  bottom: 13px;
  left: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.map_list {
  z-index: 999;
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
}

.stats_btn {
  color: #fff;
  overflow: hidden;
  border-top: 1px solid #334c88;
  border-right: 1px solid #334c88;
  border-bottom: 1px solid #334c88;
  background-color: #2a4278;
  padding: 0 15px;
  line-height: 1.8rem;
  display: none;
}

.stats-close-btn {
  position: absolute;
  background-image: url("../../assets/close.png");
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  cursor: pointer;
  z-index: 30000;
}

.zk-btn {
  background-image: url("../../assets/zk.png");
  width: 32px;
  height: 32px;
  cursor: pointer;
  z-index: 30000;
}

.zk-btn.zk-btn-sq {
  background-image: url("../../assets/sq.png");
}

#project {
  background-size: cover;
}

.building-link,
.building-link:active,
.building-link:link,
.building-link:hover,
.building-link:visited {
  color: #51bbf5;
}

.building-info {
  margin-top: 5px;
  border: 1px solid #5f7ec9;
  padding: 8px;
  z-index: 1;
  border-radius: 8px;
  // display: inline-block;
  // position: absolute;
  // background-image: url("../../assets/bg1.png");
  background: #1e2c61;

  h3 {
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
  }

  p {
    display: flex;
    font-size: 14px;
    line-height: 22px;

    .title {
      width: 88px;
    }

    .value {
      width: 40px;
      text-align: right;
    }
  }
}

.building-info-other {
  border: 1px solid #5f7ec9;
  padding: 0.23rem;
  z-index: 1;
  display: inline-block;
  position: absolute;
  background: #1e2c61;
  border-radius: 50%;
  opacity: 0.1;

  h3 {
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin: 0;
  }

  p {
    display: flex;
    font-size: 0.875rem;
    line-height: 1.375rem;

    .title {
      width: 7rem;
    }

    .value {
      width: 4rem;
      text-align: right;
    }
  }
}

#main {
  display: flex;
  // padding: 4.75rem 1.875rem 3.5rem 1.875rem;
  padding: 0;
  margin-top: 0;
  height: 100vh;
  overflow: hidden;

  #middle {
    width: 100%;
    height: 100vh;
    position: relative;

    #map {
      height: 100vh;
      // margin-bottom: 1rem;
      .project_map {
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: transparent;
      }

      .menus {
        z-index: 999;
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #21315b;
        cursor: pointer;

        > div {
          padding: 0.58rem 0.73rem;
          font-size: 0.82rem;
        }

        > div:first-child {
          border-right: 1px solid #5e7ec9;
        }

        .img {
          margin-left: 0.47rem;
          width: 0.76rem;
          height: 0.7rem;
        }
      }
    }
  }
}

#logo {
  position: absolute;
  right: 2rem;
  bottom: 1rem;
  display: flex;
  align-items: center;
  line-height: 2rem;
  z-index: 1000000;

  span {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.6);
    margin-right: 1rem;
  }
}

#mapid {
  width: 100%;
  height: 96vh;
}

.map-angle {
  background-color: red;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.leaflet-popup-content-wrapper {
  background-color: #122040;
  text-align: center;
  border: 2px solid #5f7ec9;
}

.leaflet-popup-content > div:not(:last-child) {
  margin-bottom: 5px;
}

.leaflet-popup-tip-container {
  display: none;
}
