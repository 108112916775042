


























































































































































































































































































































































































































#nation {
  overflow: hidden;
  background: url("../../assets/nation/bg.png");
  background-size: cover;
}

#title-center2 {
  width: 520px;
  height: 64px;
  display: inline-block;
  line-height: 64px;
  font-weight: bold;
  text-align: center;
  background: url("../../assets/nation/mid.png") transparent;
  background-size: contain;
}

#select-arrow {
  position: absolute;
  cursor: pointer;
  right: 60px;
  top: 20px;
  width: 24px;
  height: 24px;
  background: url("../../assets/nation/select_arrow.png") transparent;
}
#select-arrow2{
  position: absolute;
  cursor: pointer;
  right: 25px;
  top: 20px;
  width: 24px;
  height: 24px;
  background: url("../../assets/nation/select_arrow.png") transparent;
}

.custom-select {
  position: absolute;
  left: 60px;
  top: 64px;
}
.select-items {
  position: relative;
  width: 450px;
  font-size: 18px;
  line-height: 48px;
  text-align: center;
  border: 0 #254c83 solid;
  border-top: 0;
  background-color: #0b1f48;
  overflow: scroll;
  z-index: 100000;
}

.select-item {
  cursor: pointer;
}

.select-item:hover {
  background-color: #0a193a;
}

#select-light {
  position: absolute;
  width: 377px;
  height: 53px;
  background: url("../../assets/nation/light2.png") transparent;
  left: 36.5px;
  bottom: -26.5px;
  z-index: 20000;
}

#title-light {
  position: absolute;
  width: 377px;
  height: 53px;
  background: url("../../assets/nation/light2.png") transparent;
  bottom: -26.5px;
  z-index: 20000;
}

#title-left2 {
  width: 32px;
  height: 64px;
  background: url("../../assets/nation/left.png") no-repeat transparent;
  background-size: contain;
}

#title-right2 {
  width: 32px;
  height: 64px;
  background: url("../../assets/nation/right.png") no-repeat transparent;
  background-size: contain;
}
