






































































































































































































































































































































































































































































































// .empty-chart-icon {
//   width: 10rem;
//   height: 8rem;
//   background: url("../../assets/empty_chart.png") no-repeat;
//   background-size: contain;
// }
.slider-trail {
  height: 3px;
  border-radius: 1px;
  background-color: rgba(#4fc08d, 0.2);
}
