


























































































































        #project {
        background-size: cover;
        }
        #title-center2 {
  width: 620px;
  height: 64px;
  display: inline-block;
  line-height: 64px;
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  background: url("../../../assets/nation/mid.png") transparent;
  background-size: contain;
}
    #title-left2 {
    width: 32px;
    height: 64px;
    background: url("../../../assets/nation/left.png") no-repeat transparent;
    background-size: contain;
    }
    #title-right2 {
        width: 32px;
        height: 64px;
        background: url("../../../assets/nation/right.png") no-repeat transparent;
        background-size: contain;
        }
        #title-light {
  position: absolute;
  width: 377px;
  height: 53px;
  background: url("../../../assets/nation/light2.png") transparent;
  bottom: -26.5px;
  z-index: 20000;
}
.info-block {
  position: relative;
  width: 100%;
  background: url("../../../assets/nation/title_finance.png") no-repeat;
  background-size: contain;
}
.menu-block {
  position: fixed;
  top: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: none !important;
  pointer-events: none;

  .el-menu-item {
    pointer-events: all;
    padding: 0 12px;
    font-size: 15px;
    border-bottom: none;
    width: 120px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    background: url("../../../assets/nation/button.png");
  }

  .el-menu-item.is-active {
    border-bottom: none;
    background: url("../../../assets/nation/button_select.png");
  }

  .el-menu-item.suppress-active {
    background: url("../../../assets/nation/button.png") !important;
  }

  .el-menu-item:not(.is-disabled):hover,
  .el-menu-item:not(.is-disabled):focus {
    border-bottom: none;
    background-color: transparent !important;
  }
}
#main {
  display: flex;
  // padding: 4.75rem 1.875rem 3.5rem 1.875rem;
  padding: 0 1.875rem 3.5rem 1.875rem;
  margin-top: 2.75rem;
  height: calc(100vh - 10rem);
  overflow-y: scroll;

  #left {
    width: 35rem;
  }

  #right {
    width: 35rem;
  }

  #middle {
    width: 50rem;
    position: relative;
    padding: 0 1rem;
    margin: 0 auto;

    #map {
      // margin-bottom: 1rem;
      .project_map {
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: transparent;
      }

      .menus {
        z-index: 999;
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #21315b;
        cursor: pointer;

        > div {
          padding: 0.58rem 0.73rem;
          font-size: 0.82rem;
        }

        > div:first-child {
          border-right: 1px solid #5e7ec9;
        }

        .img {
          margin-left: 0.47rem;
          width: 0.76rem;
          height: 0.7rem;
        }
      }
    }
  }
}
