 /deep/ .el-date-editor {
  justify-content: space-around;
  margin-left: 1rem;
  width: 12rem !important;
  background-color: #0b1d39 !important;
  border: 1px solid #49a0e6 !important;
}
 /deep/ .el-range-input {
  background-color: #0b1d39 !important;
}
 /deep/ .el-date-editor .el-range-input {
  color: #eee !important;
}
 /deep/ .el-date-editor .el-range-separator {
  color: #ccc !important;
}
 /deep/ .el-range-separator {
  width: 12% !important;
  text-align: center !important;
}
 /deep/ .el-date-editor i {
  display: none !important;
}
.alarm-icon {
  width: 60px;
  height: 59px;
}
.option-detail {
  position: absolute;
  color: #c8dbf4;
  right: 0.5rem;
  top: 0.6rem;
  font-size: 0.6rem;
  font-weight: bold;
  padding: 4px;
  min-width: 3rem;
  border: 1px solid #0d2b50;
  text-align: center;
  cursor: pointer;
  background-color: #4999d9;
}
#alarm-drop {
  background: url("../../assets/nation/坠落报警.png");
  background-size: contain;
}
#alarm-help {
  background: url("../../assets/nation/紧急呼救.png");
  background-size: contain;
}
#alarm-danger {
  background: url("../../assets/nation/危险预警.png");
  background-size: contain;
}
#alarm-static {
  background: url("../../assets/nation/静止报警.png");
  background-size: contain;
}
.alarm-icon-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
