div > dd {
  margin-top: 1rem;
}
.head {
  margin-top: 2.5rem;
}
.value1 {
  font-size: 2rem;
  font-weight: 400 ;
  color: #F4EA27;
}
.value2 {
  font-size: 2rem;
  font-weight: 400 ;
  color: #779DFA;
}
.value3 {
  font-size: 2rem;
  font-weight: 400 ;
  color: #CB4F43;
}
