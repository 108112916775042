video {
  height: 100% !important;
  width: 100% !important;
  object-fit: fill !important;
}
.screen-division {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
}
.division-item {
  width: 4rem;
  height: 2rem;
  text-align: center;
  line-height: 2rem;
  background: #21325c;
  border: 1px solid #5f7ec9;
  cursor: pointer;
}
.page-change {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 999;
}
.active-division-type {
  color: #5accf1;
}
.content-title {
  z-index: 99;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  background-image: linear-gradient(to right, #0f1f3c, rgba(255, 255, 255, 0));
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
}
.board {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #000;
  padding-top: 3rem;
}
.no-video {
  position: absolute;
  top: 30%;
  left: 40%;
}
.people-status {
  width: 23rem;
  margin-top: 1rem;
  margin-right: 0.5rem;
}
.project-files {
  width: 20rem;
  margin-top: 1rem;
  margin-right: 0.5rem;
}
.files-detail-tiltle {
  height: 2.5rem;
  line-height: 2.5rem;
  background: #0c2341;
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  color: #00ffff;
  padding: 0 1rem;
}
.top-item {
  height: 57rem;
  width: calc(100vw - 2rem);
  display: flex;
}
.project-list {
  height: 52rem;
  padding: 0 1rem;
  overflow: scroll;
}
.project-list .company-title {
  height: 2rem;
  line-height: 2rem;
  font-size: 16px;
  font-weight: bold;
  color: #5accf1;
}
.project-list .headquarters-name {
  line-height: 2rem;
  font-weight: 400;
  color: #5accf1;
}
.project-list .sure-click {
  cursor: pointer;
}
.project-list .project-name {
  font-size: 14px;
  line-height: 1.5rem;
  font-weight: 400;
  color: #c8dbf4;
  margin-left: 1rem;
}
.project-list .point-name {
  font-size: 12px;
  font-weight: 400;
  color: #5accf1;
  margin-left: 1rem;
  text-decoration: underline;
}
.big-content {
  width: calc(100vw - 27rem);
  height: 57rem;
  margin-right: 1rem;
  border: 1px solid #0d2b50;
  position: relative;
}
.one-screen {
  width: 100%;
  height: 100%;
}
.more-screen {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
}
.four-screen-item {
  width: 49.5%;
  height: 49%;
  position: relative;
}
.nine-screen-item {
  width: 33%;
  height: 33%;
  position: relative;
}
.img {
  width: 2.5rem;
}
