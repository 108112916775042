#live-data div.content {
  padding-top: 1rem;
  margin: 0 -6px;
  overflow: hidden;
}
#live-data div.content p.item {
  display: flex;
  width: 50%;
  float: left;
}
#live-data div.content p.item .img {
  height: 2.5rem;
  width: 2.5rem;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 0.4rem;
}
#live-data div.content p.item .value {
  flex: 1;
}
#live-data div.content p.item .value .title {
  font-size: 0.8rem;
  display: block;
  color: #999999;
}
#live-data div.content p.item .value .number {
  display: block;
  font-size: 1.6rem;
  color: #ffffff;
}
#live-data div.content p.item:first-of-type .img {
  background-image: url(../../assets/01.png);
}
#live-data div.content p.item:nth-of-type(2) .img {
  background-image: url(../../assets/02.png);
}
#live-data div.content p.item:nth-of-type(3) .img {
  background-image: url(../../assets/03.png);
}
#live-data div.content p.item:nth-of-type(4) .img {
  background-image: url(../../assets/04.png);
}
#live-data div.content p.item:nth-of-type(5) .img {
  background-image: url(../../assets/05.png);
}
#live-data div.content p.item:nth-of-type(6) .img {
  background-image: url(../../assets/06.png);
}
#equipment-status .content {
  background: url('../../assets/shebei.png') no-repeat 4px 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 90px;
  height: 5.375rem;
  font-size: 0.875rem;
}
#equipment-status .content p {
  line-height: 1.5rem;
}
#people-warning-list .warning-item {
  margin-bottom: 0.75rem;
  padding: 8px 6px;
  font-size: 0.75rem;
  line-height: 1.5rem;
  background-color: #1e2c61;
  border-radius: 0.25rem;
  color: #ffffff;
  position: relative;
  display: flex;
}
#people-warning-list .warning-item div.name {
  color: #999999;
  width: 2rem;
}
#people-warning-list .warning-item div.value {
  width: 3.7rem;
  padding-left: 0.1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#people-warning-list .warning-item time {
  background-color: #577acc;
  border-radius: 4px;
  padding: 5px;
}
.chart-title {
  color: #cad6d9;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 20%;
  margin: auto;
  padding-left: 16rem;
  position: absolute;
  top: 10%;
  left: 5%;
}
